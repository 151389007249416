import React, {useContext, useEffect, useState} from "react";
import Slider from "infinite-react-carousel";
import arrow from "./img.png"
import {pages} from "../../languages/buttons";
import {promos} from "../../contents";
import {langContext} from "../../helper/langcontext";
import Cookies from "universal-cookie";

function getSliders(path, lang){
    const separatePath = path && path.split('/')
    const cookies = new Cookies({path: "/", maxAge: 31536000});
    const cs = {path: "/", maxAge: 31536000};
    if(separatePath[3]) {
        cookies.set('tra_tracker', separatePath[3], cs);
    }
    if(
        (separatePath[1] === "landing" || separatePath[1] === "get") &&
        promos.landing_slider_data &&
        separatePath[2] && separatePath[2][0] &&
        promos.landing_slider_data[lang.language][separatePath[2][0]]
    ){
        return promos.landing_slider_data[lang.language][separatePath[2][0]]
    }

    return promos.bonus_slider_data[lang.language] ?? promos.bonus_slider_data.en
}

export default function Bonus_slider({path}) {
    const lang = useContext(langContext)
    let [state, setState] = useState(getSliders(path, lang))

    useEffect(() => {
        setState(getSliders(path, lang))
    }, [lang]);

    const config = {
        swipe:false,
        autoplay: true,
        centerMode: true,
        dots: false,
        duration: 300,
        initialSlide: 1,
        shift: 150,
        slidesToShow: 1,
        autoplaySpeed: 6000,
        prevArrow: <div><img src={arrow}/></div>,
        nextArrow: <div><img src={arrow}/></div>
    };

    const bonusRender = () => {
        return state && state.map((bonus) => {

            if (typeof bonus === "string") {

                return <>
                    <div className="bonus_wrapper">
                        <div className="bonus_container">
                            <div className="bonus_pic">
                                <img src={bonus}/>
                            </div>
                            <button className="btn bonus"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = pages.login.href;
                                    }}>{pages.login[lang.language]}
                            </button>
                        </div>
                    </div>
                </>
            } else {
                return <>
                    <div className="bonus_wrapper"
                         style={{background: bonus.background ? bonus.background : "transparent"}}>
                        <div className="bonus_container">
                            {bonus.image && <div className="bonus_pic"><img
                                src={bonus.image.src}/></div>}
                            {bonus.button &&
                                <button className="btn bonus" style={bonus.button.style ? bonus.button.style : {}}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = pages.login.href;
                                        }}>{bonus.button.text ?? pages.login[lang.language]}
                                </button>}
                        </div>
                    </div>

                </>
            }


        })


    }

    return (
        <div className="bonus_slider">
            <div>
                <Slider {...config}>
                    {bonusRender()}
                </Slider>
            </div>
        </div>
    );
}
