import React, {useEffect, useState} from 'react'
import {games_data} from "../../games_data/games";
import Search_bar from "../search_bar/search_bar";
import Footer from "../footer/footer";
import {pages} from "../../languages/buttons";


export default function Game_separator({comingString, method}) {
    const pathChecker = window.location.pathname
    const path = "../games_images/350x350/"

    const separator = () => {
        switch (pathChecker) {
            case '/games/all_games':
                return "";
                break;
            case '/games/jackpot':
                return "jackpot";
                break;
            case '/games/rival':
                return "rival";
                break;
            case '/games/betsoft':
                return "betsoft";
                break;
            case '/games/saucify':
                return "saucify";
                break;
            case '/games/arrow_s_edge':
                return "arrow";
                break;
            case '/games/dragon':
                return "dragon";
                break;
            case '/games/fresh_deck':
                return "fresh";
                break;
            case '/games/qora':
                return "qora";
                break;
            case '/games/live_casino':
                return "live";
                break;
            case '/games/slots':
                return "slots";
                break;
            case '/games/new_games':
                return "new";
                break;
            case '/games/popular':
                return "popular";
                break;
            case '/games/top':
                return "top";
                break;
            case '/games/table':
                return "table";
                break;
        }
    }

    // console.log(games_data);
    let stringFilter = (string) => {
        if (string.length === 0) {
            return games_data
        }
        if (string === 'popular') {
            return games_data.filter((item)=> item['pop_rank']<30)
        }
        if (string === 'top') {
            return games_data.filter((item)=> item['pop_rank']<10)
        }
        if (string === 'table') {

            return games_data.filter((item)=> item['name'].includes('jack')||item['name'].includes('Ride'))
        }
        const searchItems = ['name', 'publisher_name', 'category_name','release_date','tag']

        return games_data.filter((item) => searchItems.some(itm => item[itm] && item[itm].toLowerCase().includes(string.toLowerCase())))

    };

    // let stringFilter = (string) => string.length ?
    //     games_data.filter((item) => ['name', 'publisher_name', 'category_name']
    //         .some(itm => item[itm].toLowerCase().includes(string.toLowerCase()))) :
    //     games_data
    // console.log(separator());
    const componentChecker = () => {
        if (method === "SEARCH") {
            return comingString
        } else {
            return separator()
        }
    }
 const newUrl = "/instantplay"
    return (
        <>

            <div className="middle_bg_pic"/>
            {method === "SEARCH" ? null : <div style={{marginTop: '50px'}}><Search_bar/></div>}
            <div className="game_separator">
                {stringFilter && stringFilter(componentChecker()).map((game, key) => {
                    // console.log(game);
                    return (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                console.log(window.location);
                                window.location.replace( newUrl);
                            }}
                            key={key} className="games_item">
                            <img  alt="" src={path + game.tile_filename}/>
                        </div>
                    )
                })}
            </div>
            <Footer/>
        </>
    )
}
