import React from "react";
import casino from "../../config";
const security = {
    "en": {
        "sections": [{
            "title": "Security",
            "content": ["Created by people with a passion for gaming, Casino "+casino.name+" is proving to be a true pioneer of online casino in Europe, incorporating an innovative interactive option. We are extremely proud to make online gaming history and we hope that you will enjoy every moment playing at our casino.", "Casino "+casino.name+" is regulated by the Kingdom of the Netherlands and is Rival Gaming’s very first European online casino."]
        }, {
            "title": "Rival gaming software",
            "content": ["Our casino software Gaming license is issued by the Curacao eGaming Licensing Authority and is a guarantee of our professionalism. Our games are safe, fair and secure.", "All our games have been developed by RIVAL Gaming and are RNG certified. This guarantees that our games are fair, honest and highly secure. The Random Number Generator and its implementation has been tested and certified for randomness according to prevailing industry standards.", "Rival Gaming is a developer of gaming software for the online casino industry. Since 2006, the company has grown successfully to become one of the leaders of this industry. The company is mostly known for having developed the range of iSlots Slot Machine games which have proved to be extremely popular with players and have attracted a lot of attention from industry experts.", "Today, there are over 120 games available on the casino software. All these games are based on themes that are visually attractive and designed to capture the attention of all players. All games are designed with matching uplifting sound tracks to create a vibrant and enticing atmosphere and help you make your way to the jackpot.", "Online casinos based on RIVAL Gaming’s technology are extremely secure and a great deal of attention is paid to the security of the players, as well as strict rules regarding all third parties involved in the running of the casino. You can therefore be sure that you are playing in a safe and fair environment."]
        }, {
            "title": "Bank transactions security: deposits and withdrawals",
            "content": ["The Casino "+casino.name+" cashier offers a security system that guarantees all your transactions to and from your online casino account. Each credit card number as well as all your personal details are transmitted using a secure connection and Digital Encryption technology. Our Digital Encryption technology is embedded within the casino software to offer a high level of security unlike so many other e-commerce systems that rely on a simple connection.", "Casino "+casino.name+" boasts a SSL connection using 128-bit encryption to ensure your online transactions – deposits and withdrawals – are secure. This encoding represents the best security currently available as it is also used by banks and other financial institutions.", "By providing you with reliable and fair gaming software, we aim to offer fast and straight forward depositing and withdrawal transactions for all our players. We understand that to earn the loyalty of all our players, we need to provide fast and efficient withdrawal procedures and we always try to ensure that each transaction is dealt with accordingly ."]
        }, {
            "title": "Fair gaming and payout rates",
            "content": ["Thanks to efficient management and low overheads, we are able to offer some of the highest payout rates in the industry with an average payout rate for all games reaching over 97%!", "Every month, our payout rates are independently audited and validated by a third party auditor in accordance with the industry standard for fair gaming. Our high payout rates are part of the reason why Casino "+casino.name+" remains to be successful."]
        }, {
            "title": "Ecogra standards",
            "content": ["Our casino software has been developed in accordance with industry quality standards recommended by Ecogra, the e-Commerce Online Gaming Regulation and Assurance regulator. We always work in accordance with the strict guidelines published by this international organization to ensure the safety of our players and maintain the highest standard of fairness and professionalism. Ultimately, the security measures in place and the high level of customer service is in place to ensure that we can provide the best service possible for our players."]
        }]
    }, "fr": {
        "sections": [{
            "title": "Sécurité",
            "content": ["Créé par des passionnés, "+casino.name+" Casino s’annonce en Europe comme un véritable casino en ligne pionnier des jeux incluant une fonction interactive novatrice. Nous sommes extrêmement fiers de marquer ainsi l’histoire du jeu en ligne et nous espérons que vous passerez des moments inoubliables en jouant chez nous. "+casino.name+" Casino dépend du Royaume des Pays Bas et est le tout premier casino en ligne européen de la marque RIVAL."]
        }, {
            "title": "Les jeux du casino",
            "content": ["La licence de jeu de Rival a été délivrée par le Curacao eGaming Licensing Authority et atteste de notre sérieux. Vous pourrez jouer en toute sécurité.", "Nos jeux élaborés par le logiciel de jeu RIVAL sont accrédités par des certificats RNG qui garantissent notre intégrité, l’équité de l’ensemble de nos jeux ainsi qu’une sécurité sans faille."]
        }, {
            "title": "Ecogra standards",
            "content": ["Rival Gaming est un développeur de logiciels de jeux de casinos en ligne. Depuis son inauguration au cours de l'année 2006, il s'est approprié le succès de façon durable. Avec ses célèbres jeux de type « I-Slot » convoités de tous, il est déjà l'auteur d'une référence dans le secteur technologique des jeux et il fait passer le niveau de performances à l'échelon suivant avec chaque nouvelle introduction de jeux.", "Aujourd'hui, il propose plus de 200 jeux en ligne. Tous ses jeux sont basés sur des thèmes visuellement accrocheurs et ces jeux sont développés de manière à susciter la fascination du plus grand nombre. La musique énergique et appropriée recrée l'environnement sur lequel l'intégralité du thème est basée. Ces thèmes ajoutent au niveau d'excitation des joueurs qui s'asseyent et jouent dans le but de gagner des espèces sonnantes et trébuchantes rapidement.", "En outre, les casinos en ligne fournis par Rival Gaming où vous devrez demander une aide technique peuvent se compter sur les doigts de la main. Pourquoi ? La raison est simple : Rival Gaming maintient de hautes normes qui filtrent les nouveaux adhérents et une grande partie des opérateurs de casinos en ligne ont du mal à respecter ces normes. Ainsi, avec les casinos en ligne Rival Gaming, vous pouvez vous attendre, entre autres, à la sécurité de vos transactions bancaires et ce, quelle que soit la somme."]
        }, {
            "title": "Sécurité des transactions bancaires : Dépôts et Retraits",
            "content": ["La banque du casino dispose d’un système de sécurité qui garantit l’ensemble de vos transactions bancaires à l’intérieur de notre casino en ligne. Premièrement, sachez que chaque numéro de cartes de crédit ainsi que toutes les informations privées en notre possession sont transmises via une connexion sécurisée et cryptée à la fois. Ce système de cryptage spécifique est directement intégré au logiciel du casino et non pas une simple connexion via Internet comme tant d'autres systèmes d'e-commerce.", ""+casino.name+" Casino possède une connexion de type SSL comprenant un degré de cryptage de 128 bits afin de sécuriser l’ensem.", "Par le biais de notre logiciel de jeu fiable et équitable, notre motivation est d'offrir des procédures de retraits transparentes et rapides auprès de tous nos joueurs. Nous savons que votre fidélité dépend de la rapidité de vos retraits de gains, voilà pourquoi nous avons décidé de simplifier au maximum vos démarches de retraits pour que vous ne soyez pas victimes de lenteurs administratives susceptibles de retarder le paiement de vos gains."]
        }, {
            "title": "Taux de reversements des gains aux joueurs : ÉQUITÉ DES JEUX",
            "content": ["Avec des frais de gestion et de structure peu élevés, nous sommes réputés pour nos taux de reversements des gains aux joueurs qui restent les plus élevés dans le monde du jeu en ligne avec une moyenne supérieure à 97%. Chaque mois, les pourcentages de reversement des gains aux joueurs sont audités et contrôlés afin de détecter d’éventuelles anomalies. "+casino.name+" Casino remporte un succès grandissant auprès de nos joueurs et on comprend pourquoi!"]
        }, {
            "title": "Respect des normes ecogra",
            "content": ["De plus, le logiciel de jeu de "+casino.name+" Casino respecte les normes recommandées par Ecogra, e-Commerce Online Gaming Régulation and Assurance. Cet organisme international est une référence auprès de milliers de casinos en ligne et respecter ses exigences est la preuve que nous sommes particulièrement sensibles à la qualité de notre casino et à la protection du joueur. Ecogra est mondialement connu et le respect de leur cahier des charges fournit l’assurance de notre honnêteté, de notre professionnalisme et de notre respect du joueur. L’ensemble de nos procédures de sécurité ainsi que le fonctionnement de notre service clients n’ont qu’un seul but : servir le joueur."]
        }]
    }, "de": {
        "sections": [{
            "title": "Sicherheit",
            "content": ["Von Menschen entwickelt, die eine Leidenschaft für Spiele haben, erweist sich "+casino.name+" mit der Vereinigung einer innovativen, interaktiven Möglichkeit als wahrer Pionier im Bereich der Online-Spielbanken in Europa. Wir sind sehr stolz darauf, Geschichte im internetbasierten Spielen schreiben zu können und wir hoffen, dass Sie jeden Moment in unserer Spielbank genießen. "+casino.name+" wird durch das vereinigte Königreich reguliert und ist die erste europäische Online Spielbank von Rival Gaming."]
        }, {
            "title": "Spielesoftware von rival",
            "content": ["Die Spiellizenz unserer Spielbank-Software wurde von der Lizenzbehörde für Onlinespiele von Curacao vergeben und stellt eine Garantie für unseren Professionalismus dar. Unsere Spiele sind sicher, fair und geschützt. All unsere Spiele wurden von RIVAL Gaming entwickelt und setzen einen zertifizierten Zufallsgenerator ein. Dies garantiert, dass unsere Spiele fair, ehrlich und mit höchster Sicherheit ablaufen. Der Zufallsgenerator und seine Integrierung wurde auf Zufälligkeit gemäß geltenden Branchenstandards getestet und zertifizneiert. Rival Gaming ist ein Entwickler von Spielesoftware für die Online-Spielbank-Branche. Seit 2006 wächst das Unternehmen erfolgreich und wurde ein führender Branchenlieferant. Das Unternehmen wurde durch die Entwicklung der iSlots- Automaten bekannt, welche unter Spielern sehr populär wurde und viel Aufmerksamkeit von Branchenexperten anzog. Heutzutage werden mehr als 120 Spiele in der Spielbank-Software angeboten. Alle Spiele basieren auf Motiven, die visuell attraktiv sind und dafür entwickelt wurden, alle Spieler anzuziehen. Alle Spiele wurden mit passendem, stimmungshebendem Soundtrack entwickelt, um eine lebendige und verlockende Atmosphäre zu gestalten und Sie auf dem Weg zum Hauptgewinn zu unterstützen. Online-Spielbanken, die auf der Technologie von RIVAL Gaming basieren. sind extrem sicher und viel Aufmerksamkeit ist sowohl der Sicherheit der Spieler, als auch der Integrierung Dritter, die das Funktionieren einer Spielbank ermöglichen, gewidmet. Deshalb können Sie sicher sein, in einer sicheren und fairen Atmosphäre zu spielen."]
        }, {
            "title": "Sicherheit von finanzprozessen: ein- und auszahlungen",
            "content": ["Die Kasse von "+casino.name+" verfügt über ein Sicherheitssystem, das all Ihre Transaktionen zu und von dem Spielbank-Konto garantiert. Sowohl jede Kreditkartennummer als auch Ihre persönlichen Daten werden in einer sicheren Verbindung und mit digitaler Verschlüsslungstechnologie übermittelt. Unsere digitale Verschlüsslungstechnologie ist in unsere Spielbank-Software integriert, um, nicht wie andere Systeme für elektronischen Geschäftsverkehr, die auf eine einfache Verbindung vertrauen, ein hohes Maß an Sicherheit anzubieten. Die Spielbank ist stolz auf seine SSL-Verbindung, die eine 128 Bit-Verschlüsslung nutzt, um zu gewährleisten, dass Ihre Online-Transaktionen – d.h. Ein- und Auszahlungen- sicher vorgenommen werden können. Diese Verschlüsslungstechnik stellt die zurzeit sicherste Form dar und wird ebenso von Banken und Finanzinstitutionen genutzt. Durch das Anbieten einer verlässlichen und fairen Spielsoftware sind wir in der Lage schnelle und direkte Ein- und Auszahlungstransaktionen für alle unsere Spieler anzubieten. Wir sind der Auffassung, dass wir für die Treue unserer Spieler, für schnelle und effiziente Auszahlungsprozeduren sorgen müssen und immer, jede Transaktion gewissenhaft durchgeführt werden muss."]
        }, {
            "title": "Faires spielen und auszahlungsraten",
            "content": ["Aufgrund eines effizienten Managements und geringen Gemeinkosten, sind wir in der Lage mit durchschnittlich mehr als 97% für alle Spiele eine der höchsten Auszahlungsraten der Branche anbieten zu können! In jedem Monat werden die Auszahlungsraten von einem unabhängig Prüfungsunternehmen in Bezug auf den Branchenstandard für faires Spielen geprüft und validiert. Die hohe Auszahlungsraten sind teilweise der Grund, warum "+casino.name+" weiterhin auf Erfolgskurs ist."]
        }, {
            "title": "Standard ecogra",
            "content": ["Unsere Software wurden gemäß den Qualitätsstandard nach Ecogra, der Regulierungsbehörde für Online-Spielregulierungen und –zusagen, entwickelt. Wir arbeiten stets nach den strikten Richtlinien, die von dieser internationalen Organisation veröffentlicht werden, um die Sicherheit für unsere Spieler zu garantieren und den höchsten Standard für faires Spielen und Professionalismus aufrecht zu erhalten. Schließlich sind die wirkenden Sicherheitsmaßnahmen und der existierende hochqualitative Kundendienst die Grundlage dafür, unseren Spielern den bestmöglichen Service anzubieten."]
        }]
    }, "es": {
        "sections": [{"title": "Seguridad", "content": []}, {
            "title": "El software de rival gaming",
            "content": ["Nuestra licencia para software en juegos de casino es expedida y otorgada por las autoridades de Curaçao eGaming la cual es una garantía de nuestra profesionalidad. Nuestros juegos son fiables, seguros y justos.", "Todos nuestros juegos han sido desarrollados por Rival y están certificados por RNG (Random Number Generator – generador aleatorio de números). Esto garantiza que nuestros juegos sean justos, honestos y muy fiables. El generador de números aleatorios y su aplicación han sido probados y certificados como aleatorios de acuerdo con estándares de la industria.", "Rival Gaming es un desarrollador de software de juego para la industria de los casinos en línea. Desde 2006 la compañía ha crecido con éxito hasta convertirse en uno de los líderes de esta industria. La empresa es principalmente conocida por haber desarrollado la gama de juegos iSlots - máquinas tragamonedas que han demostrado ser muy populares entre los jugadores y además han sabido atraer la atención por parte de expertos de la industria.", "Hoy en día, hay más de 200 juegos disponibles en el casino . Todos estos juegos se basan en temas que son visualmente atractivos y diseñados para captar la atención de sus jugadores. Todos los juegos están diseñados con pistas de sonido de alta fidelidad para crear una atmósfera vibrante y atractiva y de esta forma ayudarle en su camino hacia el premio mayor.", "Los casinos en línea que utilizan la tecnología de Rival son extremadamente seguros ya que la atención principal es la seguridad de los jugadores, así como las normas estrictas de terceros implicados en la gestión del casino. Por lo tanto, puede estar seguro de que usted está jugando en un ambiente seguro y justo."]
        }, {
            "title": "Transacciones bancarias de seguridad: Depósitos y Retiros",
            "content": ["El cajero de "+casino.name+" ofrece un sistema de seguridad que garantiza todas sus transacciones, hacia y desde su cuenta de casino en línea. Cada número de tarjeta de crédito como así todos sus datos personales se transmiten a través de una conexión segura con la tecnología de encriptamiento digital. Nuestra tecnología de encriptamiento digital se inserta dentro del programa del casino para ofrecer un alto nivel de seguridad a diferencia de muchos otros sistemas de comercio electrónicos que dependen de una conexión simple.", ""+casino.name+" cuenta con una conexión SSL con cifrado de 128 bits para asegurar que sus transacciones en línea - depósitos y retiros - son seguras. Esta codificación es la mejor seguridad disponible en la actualidad, bastante utilizada por bancos y otras instituciones financieras.", "Al proporcionarle este juego fiable y justo, nuestro objetivo es ofrecerle un avance en transacciones de depósito y retiro a todos nuestros jugadores. Entendemos que para ganarnos la confianza y lealtad de todos nuestros jugadores, tenemos que proporcionar procedimientos de retiros de ganancias más rápidos y eficientes para tratar de garantizar siempre que cada transacción se realice debidamente."]
        }, {
            "title": "Juego limpio y tasas de pago",
            "content": ["Gracias a una gestión eficiente de nuestro Casino, somos capaces de ofrecerle algunas de las tasas más altas de pago en la industria del juego en línea, una tasa de pago promedio para todos nuestros juegos del 97 %!", "Cada mes, nuestros porcentajes de pago son auditados y validados de forma independiente por un auditor de acuerdo con el estándar de la industria para la seguridad de un juego justo. Nuestros altos porcentajes de pago son la razón por la que "+casino.name+" sigue siendo un éxito."]
        }, {
            "title": "Normas de ecogra",
            "content": ["Nuestro casino ha sido desarrollado de acuerdo con los estándares de calidad recomendados por la industria Ecogra, una regulador y asegurador de comercio Online. Trabajamos siempre de acuerdo con los estrictos lineamientos publicados por esta organización internacional para garantizar la seguridad de nuestros jugadores y mantener el más alto nivel de equidad y profesionalismo.", "En última instancia, nos gustaría decir, que las medidas de seguridad y el alto nivel de servicio al cliente están a su disposición para poder asegurar que le ofrecemos el mejor servicio posible a nuestros jugadores."]
        }]
    }, "it": {
        "sections": [{
            "title": "Sicurezza",
            "content": ["Creato da persone con la passione per il gioco, Casinò "+casino.name+" ha dimostrato di essere un vero pioniere dei casino online in Europa, incorporando un'opzione interattiva innovativa. Siamo estremamente orgogliosi di fare la storia del gioco online e ci auguriamo di vederti sempre giocare al nostro casinò. Casinò "+casino.name+" è regolato dal Regno dei Paesi Bassi ed è primo vero casinò online europeo di Rival Gaming."]
        }, {
            "title": "Software di rival gaming",
            "content": ["La licenza per il gaming del software del nostro casinò è rilasciata dalla Curacao eGaming Licensing Authority, garanzia della nostra professionalità . I nostri giochi sono sicuri , equi ed onesti. Tutti i nostri giochi sono stati sviluppati da Rival Gaming e sono certificati RNG. Questo garantisce che i nostri giochi siano corretti, onesti e altamente sicuri. Il generatore randomale di numeri e la sua implementazione è stato testato e certificato secondo gli standard di settore prevalenti. Rival Gaming è uno sviluppatore di software di gioco per il settore dei casinò online. Dal 2006 , l'azienda è cresciuta con successo fino a diventare uno dei leader di questa industria. L'azienda è conosciuta soprattutto per aver sviluppato la gamma di iSlots giochi di slot machine che hanno dimostrato di essere estremamente popolari tra i giocatori e hanno attirato molta attenzione da esperti del settore. Oggi, ci sono oltre 120 giochi disponibili sul software del casinò. Tutti questi giochi sono basati su tematiche e grafiche molto attraenti e progettati per catturare l'attenzione di tutti i giocatori. Tutti i giochi sono progettati con dei tracciati audio travolgenti che creeranno un'atmosfera vivace e seducente e vi aiuterannoa raggiungere pià facilmente il jackpot. I casinò online sviluppati da Rival Gaming sono estremamente sicuri e molta attenzione è dedicata alla sicurezza dei giocatori, nonchè a terze persone che possono essere facilmente coinvolte nella gestione del casinò. Potete quindi essere certi che si sta giocando in un ambiente sicuro e giusto."]
        }, {
            "title": "Transazioni bancarie sicure: Depositi e Prelievi",
            "content": ["La cassa di Casinò "+casino.name+" offre un sistema che garantisce sicurezza a tutte le transazioni da e verso il tuo conto presso il casinò online. Ogni numero della carta di credito così come tutti i vostri dati personali vengono trasmessi tramite una connessione sicura e una tecnologia di crittografia digitale. La nostra tecnologia di crittografia digitale è inserita all'interno del software del casinò per offrire un elevato livello di sicurezza a differenza di molti altri sistemi di e-commerce che si basano su una semplice connessione internet. Casinò "+casino.name+" vanta una connessione SSL con crittografia a 128 - bit per garantire che le transazioni online - depositi e prelievi – siano sicuri. Questa codifica rappresenta la migliore sicurezza attualmente disponibile, pensare che viene utilizzata anche da banche e altri istituti finanziari. Casinò "+casino.name+" fornisce un software di gioco affidabile e leale, e noi ci proponiamo di offrire a tutti i nostri giocatori transazioni di depositi e prelievi tra i più veloci del web. Siamo consapevoli che per guadagnarsi la fedeltà di tutti i nostri giocatori, dobbiamo garantire procedure di ritiro veloci."]
        }, {
            "title": "Regole di gioco e tasso di vincita",
            "content": ["Grazie a una gestione efficiente e a basse spese, siamo in grado di offrire uno dei peyout pi+ alti del web, con un tasso medio di vincita per tutti i giochi che supera il 97%! Ogni mese, i nostri tassi di pagamento sono verificati in modo indipendente e convalidati da un sindacato esterno in conformità con gli standards industriali che garantoscono un gioco online leale e sicuro. Il nostro altissimo payout è una delle ragioni per le quali Casinò "+casino.name+" ha così tanto successo sul web"]
        }, {
            "title": "Standards ecogra",
            "content": ["ll software del nostro casinò è stato sviluppato in conformità con gli standard di del settore raccomandate da eCOGRA , il regulatore e-Commerce Online Gaming Regulation e Assurance. Lavoriamo sempre in conformità con le rigorose linee guida pubblicate da tale organizzazione internazionale per garantire la sicurezza dei nostri giocatori e per mantenere i più alti standard di correttezza e professionalità. In definitiva, le misure di sicurezza in atto e l'alto livello di servizio al cliente vuole assicurare a tutti i giocatori il miglior servizio possibile."]
        }]
    },
    "pt": {
        "sections": [{
        "title": "Segurança",
        "content": ["Criado por pessoas apaixonadas por jogos, o Casino " + casino.name + " está se tornando um verdadeiro pioneiro dos cassinos online na Europa, incorporando uma opção inovadora e interativa. Estamos extremamente orgulhosos de fazer história nos jogos online e esperamos que você aproveite cada momento jogando em nosso cassino.", "O Casino " + casino.name + " é regulamentado pelo Reino dos Países Baixos e é o primeiro cassino online europeu da Rival Gaming."]
        }, {
        "title": "Software da Rival Gaming",
        "content": ["Nosso software de cassino possui uma licença de jogos emitida pela Autoridade de Licenciamento de Jogos de Curaçao, o que garante nossa profissionalismo. Nossos jogos são seguros, justos e protegidos.", "Todos os nossos jogos foram desenvolvidos pela RIVAL Gaming e possuem certificação RNG (Gerador de Números Aleatórios). Isso garante que nossos jogos sejam justos, honestos e altamente seguros. O Gerador de Números Aleatórios e sua implementação foram testados e certificados para aleatoriedade, de acordo com os padrões da indústria.", "A Rival Gaming é uma desenvolvedora de software de jogos para a indústria de cassinos online. Desde 2006, a empresa cresceu com sucesso e tornou-se uma das líderes dessa indústria. A empresa é mais conhecida por ter desenvolvido a linha de jogos de máquinas caça-níqueis iSlots, que se mostraram extremamente populares entre os jogadores e têm atraído muita atenção de especialistas da indústria.", "Atualmente, existem mais de 120 jogos disponíveis no software do cassino. Todos esses jogos são baseados em temas visualmente atraentes e projetados para capturar a atenção de todos os jogadores. Todos os jogos são projetados com trilhas sonoras envolventes para criar uma atmosfera vibrante e atraente e ajudá-lo a chegar ao jackpot.", "Os cassinos online baseados na tecnologia da RIVAL Gaming são extremamente seguros, e muita atenção é dedicada à segurança dos jogadores, bem como a regras estritas relacionadas a todas as terceiras partes envolvidas na operação do cassino. Portanto, você pode ter certeza de que está jogando em um ambiente seguro e justo."]
        }, {
        "title": "Segurança de transações bancárias: depósitos e saques",
        "content": ["O caixa do Casino " + casino.name + " oferece um sistema de segurança que garante todas as suas transações de e para a sua conta de cassino online. Cada número de cartão de crédito, bem como todos os seus dados pessoais, são transmitidos usando uma conexão segura e tecnologia de criptografia digital. Nossa tecnologia de criptografia digital está incorporada ao software do cassino para oferecer um alto nível de segurança, ao contrário de muitos outros sistemas de comércio eletrônico que dependem de uma conexão simples.", "O Casino " + casino.name + " possui uma conexão SSL usando criptografia de 128 bits para garantir que suas transações online - depósitos e saques - sejam seguras. Essa codificação representa a melhor segurança atualmente disponível, pois também é usada por bancos e outras instituições financeiras.", "Ao fornecer um software de jogos confiável e justo, nosso objetivo é oferecer transações de depósito e saque rápidas e diretas para todos os nossos jogadores. Sabemos que, para conquistar a fidelidade de todos os nossos jogadores, precisamos fornecer procedimentos de saque rápidos e eficientes, e sempre nos esforçamos para lidar com cada transação adequadamente."]
        }, {
        "title": "Jogo justo e taxas de pagamento",
        "content": ["Graças a uma gestão eficiente e custos operacionais baixos, somos capazes de oferecer algumas das maiores taxas de pagamento do setor, com uma taxa média de pagamento para todos os jogos de mais de 97%!", "Todos os meses, nossas taxas de pagamento são auditadas e validadas de forma independente por um auditor terceirizado, de acordo com o padrão da indústria para jogos justos. Nossas altas taxas de pagamento são parte do motivo pelo qual o Casino " + casino.name + " continua sendo bem-sucedido."]
        }, {
        "title": "Padrões da Ecogra",
        "content": ["Nosso software de cassino foi desenvolvido de acordo com os padrões de qualidade da indústria recomendados pela Ecogra, o regulador de Jogos Online de Comércio Eletrônico e Garantia. Sempre trabalhamos de acordo com as diretrizes estritas publicadas por esta organização internacional para garantir a segurança de nossos jogadores e manter o mais alto padrão de justiça e profissionalismo. Em última análise, as medidas de segurança implementadas e o alto nível de atendimento ao cliente garantem que possamos oferecer o melhor serviço possível para nossos jogadores."]
        }]
        }
}
export default security;