import React, { useContext } from "react";
import { langContext } from "../../../helper/langcontext";

export default function Header({ isOpenCb, data }) {
  const { language } = useContext(langContext);
  return (
    <div className="Header-Component">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="logo">
          <img
            onClick={() => {
              window.location.href = "/";
            }}
            src={data?.logo?.img}
            alt="logo"
          />
        </div>
        <div className="info">
          <span
            onClick={() => isOpenCb(true)}
            className="btn-lp"
            onMouseEnter={(e) => {
              e.target.style.backgroundColor =
                data.infoBtn.bgHover ?? "#ffffff";
              e.target.style.color = data.infoBtn.fontHoverColor ?? "#000000";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = data.infoBtn.bg ?? "#000000";
              e.target.style.color = data.infoBtn.fontColor ?? "#ffffff";
            }}
            style={{
              color: data.infoBtn.fontColor,
              marginTop: data.infoBtn.top,
              backgroundColor: data.infoBtn.bg,
              border: `2px solid ${data?.infoBtn.border}`,
            }}
          >
            {data?.infoBtn["text"][language]}
          </span>
        </div>
      </div>
    </div>
  );
}
