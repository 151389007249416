import React, {useState} from "react";
import {langContext} from "../helper/langcontext";
import {useContext} from "react";
import Modal from "../components/modal/modal";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import {pages} from "../languages/buttons";
import {promos} from "../contents";

export default function Promotions() {
    const lang = useContext(langContext);
    const [modalOpen, setModalOpen] = useState(false)
    const [cardData, setCardData] = useState("")

    return (
        <>
            <Header/>
            <div className="middle_bg_pic"/>
            <div className="middle_content pages">
                <div className="promotions_wrapper">
                    {promos.promo_data.map((x, key) => {
                        if(x.name[lang.language] === undefined){
                            return
                        }
                        return <div key={key} className="promotion_section">
                            <div className="promo_left">
                                <div className="pCard_left">
                                    <span className="pCard_name">{x.name[lang.language]}</span>
                                    <span className={"pCard_details"}>{x.bonus_details[lang.language]}</span>
                                </div>
                                <div className="pCard_right">
                                    <button type="button" className="btn header st"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = pages.login.href;
                                            }}
                                    >Get Bonus
                                    </button>
                                    <a onClick={() => {
                                        setCardData(x)
                                        setModalOpen(x => !x)
                                    }
                                    }>Details</a>
                                </div>
                            </div>
                            <div className="promo_right">
                                <img src={x.img}/>
                            </div>
                        </div>
                    })}</div>
            </div>
            <Modal data={cardData} onRequestOpen={modalOpen} onRequestClose={(value) => {
                setModalOpen(value)
            }}/>
            <Footer/>
        </>
    );
}
