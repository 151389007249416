import React from "react";
import casino from "../../config";

const responsible = {
    "en": {
        "sections": [{
            "title": "RESPONSIBLE GAMING POLICY",

            "content": ["" + casino.name + " is dedicated to providing its members with a responsible gaming environment. We place high importance to creating a safe environment for our players and preventing compulsive usage of our gaming products," +
            " as well as underage access.",
                "As a responsible," +
                " regulated gaming company," +
                " we comply with all the necessary guidelines and regulations.",
                "In order to do so," +
                " " + casino.name + " implemented the most advanced safety measures as follows:"]
        },
            {
                "title": "PROTECTION OF MINORS",

                "content": ["Minors under the age of 18 (or the age of legal consent in your jurisdiction) are prohibited to play in " + casino.name + "’s online games. Our online casino games are not designed for children or adolescents. We actively discourage and track minors under the age of 18 (or the age of legal consent in your jurisdiction) who attempt to play at our websites and immediately block their access.",
                    "Along with this," +
                    " as internet services are accessible all over," +
                    " we recommend parents to install child protection software in order to block access of minors from gambling websites.",
                    "Tips for parents:",

                    <ul>
                        <li>Do not leave your computer unattended when the casino website is running. Please take extra
                            care to ensure that our service is not accessible to children through your devices.
                        </li>
                        <li>Protect your gambling programs with password and keep your login details out of reach of
                            children.
                        </li>
                        <li>Do not allow minors under the age of 18 (or the age of legal consent in your jurisdiction)
                            to participate in any gambling activity.
                        </li>
                        <li>Inform your children about the illegality and the potential damage of underage gambling.
                        </li>
                        <li>Limit the amount of time your children spend online.</li>
                    </ul>,
                    <>Important: No software is free of holes. If you know a minor under the age of 18 (or the age of
                        legal consent in your jurisdiction) who is registered to our site, please notify us as soon as
                        possible at {casino.email}</>]
            },
            {
                "title": "PREVENTING COMPULSIVE GAMBLING",

                "content": ["While majority of members play for fun and entertainment," +
                " some players develop addiction to online gaming. Losing significant amount of money as a result of gambling can lead to different problems in all major areas of life.",
                    "We take this issue very seriously and therefore implemented the following measures to help coping with addiction:",
                    <ul>
                        <li>Our professional employees are trained to be able to detect compulsive gambling and underage
                            gambling. Once detected, appropriate actions are taken.
                        </li>
                        <li>Self-exclusion program which allow an easy way to request cancelling of membership. Shortly
                            after the request is received by us, we will block the member’s account.
                        </li>
                        <li>Allowing the member to set his own deposit amount limit.</li>
                        <li>Removing the member from our email list upon written request.</li>
                    </ul>,
                    "Important:",
                    <ul>
                        <li>Counselling proved to have a major positive effect on people who are prone to compulsive
                            gambling.
                        </li>
                        <li>Gambling is a form of entertainment only,
                            it does not replace income from other sources. Never bet your rent money and money you need
                            for everyday necessity.
                        </li>
                        <li>Gambling is a game of chance,
                            winning is not guaranteed in any stage.
                        </li>
                        <li>Make sure that the decision to gamble is your own and that you were not influenced by
                            others.
                        </li>
                        <li>Never try to chase your losses. Check the amounts you spend on a regular basis.</li>
                        <li>Make sure you know the rules of the games you play.</li>
                    </ul>,
                    "Moderation is the key to avoid addiction to gambling. Be aware."]
            },
            {
                "title": "SEEK HELP",

                "content": ["The following questions will help you understand if you have an addiction problem:",

                    <ul>
                        <li>Do you gamble in order to avoid coping with your everyday life?</li>
                        <li>Do you miss work or school in order to gamble?</li>
                        <li>Have you lost interest in your family,
                            friends or hobbies?
                        </li>
                        <li>Do other people criticize your gambling habits?</li>
                        <li>Do you gamble all your money,
                            including the money supposed to be used for rent and bills?
                        </li>
                        <li>Have you ever lied to cover up the amount of money or time you spend on gambling?</li>
                        <li>When you gamble and lose all your deposit,
                            do you have a feeling of lost and despair and and have an urge to gamble again as soon as
                            possible?
                        </li>
                        <li>Do you feel depressed or even suicidal because of your gambling?</li>
                    </ul>,
                    "If you replied yes to one or more of the questions above," +
                    " or if you have any concerns about addiction to gambling," +
                    " here are a few organizations you can turn to:",
                    <ul>
                        <li>Gamblers Anonymous: <a
                            href="http://www.gamblersanonymous.org/ga">http://www.gamblersanonymous.org/ga/ </a></li>
                        <li>Gambling Therapy: <a
                            href='https://www.gamblingtherapy.org/en\'> https://www.gamblingtherapy.org/en</a></li>
                        <li>Global Gambling Guidance Group (G4): <a href='https://www.gx4.com'>https://www.gx4.com/</a>
                        </li>
                    </ul>]
            },
            {
                "title": "SELF-LIMIT",

                "content": ["We recommend that you use our tool of self-limit and control the amount of money that you use to gamble in our site. By a simple written request from our support team," +
                " we will set limit on your account.",
                    "While setting a limit," +
                    " bear in mind how often you play and how long is each session. In addition," +
                    " decide in advance what is an acceptable amount for losing. According to your answer," +
                    " set a daily," +
                    " weekly and monthly limit.",
                    "At any time," +
                    " you can contact our support team at "+casino.support_email +" and request to take a break for a maximum of six weeks. In this period of time," +
                    " we will limit your access to the account and to new accounts that you will attempt to open.",
                    "At any time," +
                    " you can turn to our support team and request to close your account completely."]
            },
            {
                "title": "SUPPORT",

                "content": [<>For any further assistance or questions,
                    please don’t hesitate to contact our support team at {casino.support_email}</>]
            }]
    },
    "fr": {
        "sections": [{
            "title": "POLITIQUE DE JEU RESPONSABLE",

            "content": ["" + casino.name + " s'engage à fournir à ses membres un environnement de jeu responsable. Nous accordons une grande importance à la création d'un environnement sûr pour nos joueurs et à la prévention de l'utilisation compulsive de nos jeux," +
            " ainsi que de l'accès des mineurs.",
                "En tant qu'entreprise de jeu responsable et réglementée," +
                " nous respectons toutes les directives et réglementations nécessaires.",
                "Pour ce faire," +
                " " + casino.name + " a mis en place les mesures de sécurité les plus avancées comme suit:"]
        },
            {
                "title": "PROTECTION DES MINEURS",

                "content": ["Les mineurs de moins de 18 ans (ou l'âge du consentement légal dans votre juridiction) ne sont pas autorisés à jouer sur les jeux en ligne de " + casino.name + ". Nos jeux de casino en ligne ne sont pas conçus pour les enfants ou les adolescents. Nous décourageons et suivons activement les mineurs de moins de 18 ans (ou l'âge du consentement légal dans votre juridiction) qui tentent de jouer sur nos sites Web et bloquent immédiatement leur accès.",
                    "Parallèlement à cela," +
                    " comme les services Internet sont accessibles partout," +
                    " nous recommandons aux parents d'installer un logiciel de protection des enfants afin de bloquer l'accès des mineurs aux sites de jeux d'argent.",
                    "Conseils aux parents:",
                    <ul>
                        <li>Ne laissez pas votre ordinateur sans surveillance lorsque le site Web du casino fonctionne.
                            Veuillez prendre des précautions supplémentaires pour vous assurer que notre service n'est
                            pas accessible aux enfants via vos appareils.
                        </li>
                        <li>Protégez vos programmes de jeu avec un mot de passe et gardez vos informations de connexion
                            hors de la portée des enfants.
                        </li>
                        <li>Ne permettez pas aux mineurs de moins de 18 ans (ou à l'âge du consentement légal dans votre
                            juridiction) de participer à des activités de jeu.
                        </li>
                        <li>Informez vos enfants de l'illégalité et des dommages potentiels du jeu des mineurs.</li>
                        <li>Limitez le temps que vos enfants passent en ligne.</li>
                    </ul>,
                    "Important: aucun logiciel n'est exempté. Si vous connaissez un mineur de moins de 18 ans (ou l'âge du consentement légal dans votre juridiction) qui est inscrit sur notre site," +
                    " veuillez nous en informer dès que possible à "+casino.support_email +""]
            },
            {
                "title": "EMPÊCHER LE JEU COMPULSIF",

                "content": ["Alors que la majorité des membres jouent pour le plaisir et le divertissement," +
                " certains joueurs développent une dépendance au jeu en ligne. Perdre une somme importante d'argent à la suite du jeu peut entraîner différents problèmes dans tous les principaux domaines de la vie.",
                    "Nous prenons ce problème très au sérieux et avons donc mis en œuvre les mesures suivantes pour aider à faire face à la dépendance:",
                    <ul>
                        <li>Nos employés professionnels sont formés pour être en mesure de détecter les joueurs
                            compulsifs et les joueurs mineurs. Une fois détecté,
                            les actions appropriées sont prises.
                        </li>
                        <li>Programme d'auto-exclusion permet de demander facilement l'annulation de l'adhésion. Peu de
                            temps après réception de la demande,
                            nous bloquerons le compte du membre.
                        </li>
                        <li>Vous avez la possibilité de définir votre propre limite de montant de dépôt.</li>
                        <li>Vous pouvez vous retirer de notre liste de diffusion sur demande écrite.</li>
                    </ul>,
                    "Important:",
                    <ul>
                        <li>L’orientation du joueur s'est révélé avoir un effet positif majeur sur les personnes sujets
                            au jeu compulsif.
                        </li>
                        <li>Le jeu est une forme de divertissement uniquement,
                            il ne remplace pas les revenus provenant d'autres sources. Ne pariez jamais votre argent de
                            loyer et l'argent dont vous avez besoin pour une nécessité quotidienne.
                        </li>
                        <li>Le jeu est un jeu de hasard,
                            gagner n'est garanti à aucune étape.
                        </li>
                        <li>Assurez-vous que la décision de jouer vous appartient et que vous n'avez pas été influencé
                            par les autres.
                        </li>
                        <li>N'essayez jamais de chasser vos pertes. Vérifiez régulièrement les montants que vous
                            dépensez.
                        </li>
                        <li>Assurez-vous de connaître les règles des jeux auxquels vous jouez.</li>
                    </ul>,
                    "La modération est la clé pour éviter la dépendance au jeu. Être conscient."]
            },
            {
                "title": "DEMANDER DE L'AIDE",

                "content": ["Les questions suivantes vous aideront à comprendre si vous avez un problème de dépendance:",
                    "<ul> <li>Jouez-vous pour éviter de faire face à votre quotidien?</li> <li>Vous manquez le travail ou l'école pour jouer?</li> <li>Avez-vous perdu tout intérêt pour votre famille," +
                    " vos amis ou vos loisirs?</li> <li>D'autres personnes critiquent-elles vos habitudes de jeu?</li> <li>Jouez-vous tout votre argent," +
                    " y compris l'argent censé être utilisé pour le loyer et les factures??</li> <li>Avez-vous déjà menti pour couvrir le montant d'argent ou le temps que vous passez à jouer?</li> <li>Lorsque vous jouez et perdez tout votre dépôt," +
                    " avez-vous un sentiment de perte et de désespoir et avez-vous une envie de jouer à nouveau dès que possible?</li> <li>Vous sentez-vous déprimé ou même suicidaire à cause de votre jeu?</li> </ul>",
                    "Si vous avez répondu oui à une ou plusieurs des questions ci-dessus," +
                    " ou si vous avez des inquiétudes concernant la dépendance au jeu," +
                    " voici quelques organisations vers lesquelles vous pouvez vous tourner:",
                    <ul>
                        <li>Gamblers Anonymous: <a
                            href="http://www.gamblersanonymous.org/ga">http://www.gamblersanonymous.org/ga/ </a></li>
                        <li>Gambling Therapy: <a
                            href="https://www.gamblingtherapy.org/en"> https://www.gamblingtherapy.org/en</a>/
                        </li>
                        <li>Global Gambling Guidance Group (G4): <a href="https://www.gx4.com">https://www.gx4.com/</a>
                        </li>
                    </ul>]
            },
            {
                "title": "LIMITATION DE SOI",

                "content": ["Nous vous recommandons d'utiliser notre outil d'autolimitations et de contrôler le montant que vous voulez utiliser pour jouer sur notre site. Sur simple demande écrite de notre équipe d'assistance," +
                " nous fixerons la limite de votre compte.",
                    "Lorsque vous définissez une limite," +
                    " gardez à l'esprit la fréquence à laquelle vous jouez et la durée de chaque session. De plus," +
                    " décidez à l'avance du montant acceptable à perdre. Selon votre réponse," +
                    " définissez une limite quotidienne," +
                    " hebdomadaire et mensuelle.",
                    "À tout moment," +
                    " vous pouvez contacter notre équipe d'assistance à "+casino.support_email +" et demander à prendre une pause pour un maximum de six semaines. Pendant cette période," +
                    " nous limiterons votre accès au compte et aux nouveaux comptes que vous tenterez d'ouvrir.",
                    "À tout moment," +
                    " vous pouvez vous adresser à notre équipe d'assistance et demander la fermeture complète de votre compte."]
            },
            {
                "title": "SUPPORT",

                "content": [<>Pour toute assistance ou question,
                    n'hésitez pas à contacter notre équipe d'assistance à {casino.support_email}.</>]
            }]
    },
    "de": {
        "sections": [{
            "title": "VERANTWORTLICHE SPIELPOLITIK",

            "content": ["" + casino.name + " ist bestrebt," +
            " seinen Mitgliedern eine verantwortungsvolle Spielumgebung zu bieten. Wir legen großen Wert darauf," +
            " ein sicheres Umfeld für unsere Spieler zu schaffen und die zwanghafte Nutzung unserer Spieleprodukte," +
            " sowie den Zugang von Minderjährigen zu verhindern.",
                "Als verantwortungsbewusstes," +
                " reguliertes Glücksspielunternehmen halten wir uns an alle notwendigen Richtlinien und Vorschriften.",
                "Zu diesem Zweck hat " + casino.name + " die fortschrittlichsten Sicherheitsmaßnahmen wie folgt implementiert:"]
        },
            {
                "title": "SCHUTZ VON MINDERJÄHRIGEN",

                "content": ["Minderjährige unter 18 Jahren (oder entsprechend dem Alter der gesetzlichen Einwilligung in Ihrem Land) dürfen nicht an der Online-Spielbank von " + casino.name + " teilnehmen. Unsere Online-Spielbanken sind nicht für Kinder oder Jugendliche konzipiert. Wir raten Minderjährigen unter 18 Jahren (oder dem Alter der gesetzlichen Bestimmung in Ihrer Gerichtsbarkeit)," +
                " die versuchen," +
                " auf unseren Websites zu spielen," +
                " aktiv vom Spielen ab und verfolgen sie.",
                    "Da Internetdienste überall verfügbar sind," +
                    " empfehlen wir Eltern," +
                    " eine Kinderschutzsoftware zu installieren," +
                    " um den Zugriff von Minderjährigen auf Glücksspielwebsites zu blockieren.",
                    "Tipps für Eltern:",
                    <ul>
                        <li>Lassen Sie Ihren Computer nicht unbeaufsichtigt,
                            wenn die Spielbank-Website geöffnet wird. Bitte achten Sie besonders darauf,
                            dass unser Service für Kinder über Ihre Geräte nicht zugänglich ist.
                        </li>
                        <li>Schützen Sie Ihre Glücksspielprogramme mit einem Passwort und bewahren Sie Ihre Anmeldedaten
                            außerhalb der Reichweite von Kindern auf.
                        </li>
                        <li>Erlauben Sie Minderjährigen unter 18 Jahren (oder dem Alter der gesetzlichen Einwilligung in
                            Ihrer Gerichtsbarkeit) nicht,
                            an Glücksspielaktivitäten teilzunehmen.
                        </li>
                        <li>Informieren Sie Ihre Kinder über die Illegalität und den möglichen Schaden von Glücksspielen
                            bei Minderjährigen.
                        </li>
                        <li>Begrenzen Sie die Zeit,
                            die Ihre Kinder online verbringen.
                        </li>
                    </ul>,
                    <>Wichtig: Keine Software ist frei von Fehlern. Wenn Sie einen Minderjährigen unter 18 Jahren (oder
                        im Alter der gesetzlichen Einwilligung in Ihrem Land) kennen,
                        der auf unserer Website registriert ist,
                        benachrichtigen Sie uns bitte so bald wie möglich unter {casino.support_email}</>]
            },
            {
                "title": "ZWANGHAFTES GLÜCKSSPIEL VERHINDERN",

                "content": ["Während die Mehrheit der Mitglieder zum Spaß und zur Unterhaltung spielt," +
                " entwickeln einige Spieler eine Sucht nach Online-Spielen. Der Verlust eines erheblichen Geldbetrags durch Glücksspiele kann in allen wichtigen Lebensbereichen zu unterschiedlichen Problemen führen.",
                    "Wir nehmen dieses Problem sehr ernst und haben daher die folgenden Maßnahmen zur Bewältigung der Sucht umgesetzt:",
                    <ul>
                        <li>Unsere professionellen Mitarbeiter sind geschult,
                            um zwanghaftes Glücksspiel und minderjähriges Glücksspiel zu erkennen. Sobald sie erkannt
                            wurden,
                            werden geeignete Maßnahmen ergriffen.
                        </li>
                        <li>Selbstausschlussprogramm,
                            mit dem auf einfache Weise die Kündigung der Mitgliedschaft beantragt werden kann. Kurz
                            nachdem die Anfrage bei uns eingegangen ist,
                            werden wir das Konto des Mitglieds sperren.
                        </li>
                        <li>Sie können Ihr eigenes Einzahlungslimit festlegen</li>
                        <li>Sie werden auf schriftliche Anfrage hin von unserer E-Mail-Liste entfernt.</li>
                    </ul>,
                    "Wichtig:",
                    <ul>
                        <li>Eine Beratung wirkte sich bereits sehr positiv auf Menschen aus,
                            die zu zwanghaftem Glücksspiel neigen.
                        </li>
                        <li>Glücksspiel ist nur eine Form der Unterhaltung,
                            es ersetzt nicht das Einkommen aus anderen Quellen. Wetten Sie niemals auf Ihr Mietgeld
                            sowie Geld,
                            das Sie für den täglichen Bedarf benötigen.
                        </li>
                        <li>Glücksspiel ist ein Spiel des Glücks. Das Gewinnen ist in keiner Phase garantiert.</li>
                        <li>Stellen Sie sicher,
                            dass die Entscheidung zum Spielen Ihre eigene ist und dass Sie nicht von anderen beeinflusst
                            wurden.
                        </li>
                        <li>Versuchen Sie niemals,
                            Ihre Verluste auszugleichen. Überprüfen Sie die Beträge,
                            die Sie regelmäßig ausgeben.
                        </li>
                        <li>Stellen Sie sicher,
                            dass Sie die Regeln der Spiele kennen,
                            die Sie spielen.
                        </li>
                    </ul>,
                    "Zurückhaltung ist der Schlüssel," +
                    " um eine Spielsucht zu vermeiden. Seien Sie vorsichtig."]
            },
            {
                "title": "HILFE SUCHEN",

                "content": ["Die folgenden Fragen helfen Ihnen zu erkennen," +
                " wenn Sie ein Suchtproblem haben:",
                    <ul>
                        <li>Spielen Sie,
                            um nicht mit Ihrem Alltag fertig zu werden?
                        </li>
                        <li>Verpassen Sie die Arbeit oder Schule,
                            um zu spielen?
                        </li>
                        <li>Haben Sie das Interesse an Ihrer Familie,
                            Ihren Freunden oder Hobbys verloren?
                        </li>
                        <li>Kritisieren Sie andere Leute für Ihre Spielgewohnheiten?</li>
                        <li>Verspielen Sie Ihr ganzes Geld,
                            einschließlich des Geldes,
                            das für Miete und Rechnungen verwendet werden soll?
                        </li>
                        <li>Haben Sie jemals gelogen,
                            um den Geld- oder Zeitaufwand für Glücksspiele zu vertuschen?
                        </li>
                        <li>Wenn Sie spielen und Ihre gesamte Einzahlung verlieren,
                            haben Sie ein Gefühl von Verlust und Verzweiflung und den Drang,
                            so schnell wie möglich wieder zu spielen?
                        </li>
                        <li>Fühlen Sie sich aufgrund Ihres Glücksspiels depressiv oder sogar selbstmörderisch?</li>
                    </ul>,
                    "Wenn Sie eine oder mehrere der oben genannten Fragen mit Ja beantwortet haben oder Bedenken hinsichtlich der Spielsucht haben," +
                    " können Sie sich an folgende Organisationen wenden:",
                    <ul>
                        <li>Gamblers Anonymous:<a
                            href="https://www.spielen-mit-verantwortung.de/hilfe-und-unterstuetzung"> https://www.spielen-mit-verantwortung.de/hilfe-und-unterstuetzung </a>
                        </li>
                        <li>Gambling Therapy: <a
                            href="https://www.gamblingtherapy.org/de"> https://www.gamblingtherapy.org/de </a></li>
                        <li>Global Gambling Guidance Group (G4): <a href="https://www.gx4.com">https://www.gx4.com</a>
                        </li>
                    </ul>]
            },
            {
                "title": "SELBSTBEGRENZUNG",

                "content": ["Wir empfehlen Ihnen," +
                " unser Tool zur Selbstbegrenzung zu verwenden und den Geldbetrag zu kontrollieren," +
                " mit dem Sie auf unserer Website spielen. Durch eine einfache schriftliche Anfrage an unsere Support-Teams legen wir ein Limit für Ihr Konto fest. Denken Sie beim Festlegen eines Limits daran," +
                " wie oft Sie spielen und wie lange jede Sitzung dauert. Entscheiden Sie außerdem im Voraus," +
                " welcher Betrag für den Verlust akzeptabel ist.",
                    "Legen Sie gemäß Ihrer Antwort ein Tages-," +
                    " Wochen- und Monatslimit fest.",
                    " \"Sie können sich jederzeit unter " + casino.support_email + " an unser Support-Team wenden und eine Pause von maximal sechs Wochen beantragen. In diesem Zeitraum beschränken wir Ihren Zugriff auf das Konto und auf neue Konten," +
                    " die Sie eröffnen möchten.",
                    "Sie können sich jederzeit an unser Support-Team wenden und die vollständige Schließung Ihres Kontos beantragen."]
            },
            {
                "title": "UNTERSTÜTZUNG",

                "content": [<>Für weitere Unterstützung oder Fragen wenden Sie sich bitte an unser Support-Team
                    unter {casino.support_email}</>]
            }]
    },
    "es": {
        "sections": [{
            "title": "POLÍTICA DE JUEGO RESPONSABLE",

            "content": ["" + casino.name + " le proporciona a sus miembros un entorno de juego responsable. Damos gran importancia a crear un entorno seguro para nuestros jugadores y evitar el uso compulsivo de nuestros productos de juego," +
            " así como el acceso de menores de edad.",
                "Como empresa de juego responsable y regulada," +
                " cumplimos con todas las pautas y regulaciones necesarias.",
                "Para hacerlo," +
                " " + casino.name + " implementó las medidas de seguridad más avanzadas de la siguiente manera:"]
        },
            {
                "title": "PROTECCIÓN DE MENORES",

                "content": ["Los menores de 18 años (o la edad de consentimiento legal en su jurisdicción) tienen prohibido jugar en los juegos en línea de " + casino.name + ". Nuestros juegos de casino en línea no están hechos para niños o adolescentes. Rastreamos activamente a los menores de 18 años (o la edad de consentimiento legal en su jurisdicción) que intentan jugar en nuestros sitios web e inmediatamente bloqueamos su acceso.",
                    "Junto con esto," +
                    " como los servicios de Internet son accesibles en todas partes," +
                    " recomendamos a los padres que instalen un software de protección infantil para bloquear el acceso de menores de los sitios web de juegos de azar.",
                    "Consejos para los padres:",
                    <ul>
                        <li>No deje su computadora desatendida cuando esté en el sitio web del casino. Tenga especial
                            cuidado para garantizar que nuestro servicio no esté al alcance de niños ni sea accesible a
                            través de sus dispositivos.
                        </li>
                        <li>Proteja sus programas de juego con contraseña y mantenga sus datos de inicio de sesión fuera
                            del alcance de los niños.
                        </li>
                        <li>No permita que menores de 18 años (o la edad de consentimiento legal en su jurisdicción)
                            participen en ninguna actividad de juego.
                        </li>
                        <li>Informe a sus hijos sobre la ilegalidad y el daño potencial del juego de menores.</li>
                        <li>Limite la cantidad de tiempo que sus hijos pasan en línea.</li>
                    </ul>,
                    <>Importante:Ningún software está libre de errores. Si conoce a un menor de 18 años (o la edad de
                        consentimiento legal en su jurisdicción) que está registrado en nuestro sitio,
                        favor notificarlo lo antes posible a {casino.support_email}</>]
            },
            {
                "title": "PREVENCIÓN DE JUEGOS OBLIGATORIOS",

                "content": ["Si bien la mayoría de los miembros juegan por diversión y entretenimiento," +
                " algunos jugadores desarrollan adicción a los juegos en línea. Perder una cantidad significativa de dinero como resultado del juego puede conducir a diferentes problemas en todas las áreas principales de la vida.",
                    "Nos tomamos muy en serio este problema y," +
                    " por lo tanto," +
                    " implementamos las siguientes medidas para ayudar a hacer frente a esta adicción:",
                    <ul>
                        <li>Nuestros empleados profesionales están capacitados para detectar juegos de azar compulsivos
                            y en menores de edad. Una vez detectado,
                            se toman las medidas apropiadas.
                        </li>
                        <li>El programa de autoexclusión,
                            permite de una manera fácil solicitar la cancelación de la membresía. Poco después de que
                            recibamos la solicitud,
                            bloquearemos la cuenta del miembro.
                        </li>
                        <li>Permitimos que el miembro establezca su propio límite de depósito.</li>
                        <li>Eliminamos al miembro de nuestra lista de correo electrónico con previa solicitud por
                            escrito.
                        </li>
                    </ul>,
                    "Importante:",
                    <ul> <li>La consejería demostró tener un efecto positivo importante en las personas propensas al juego compulsivo.</li> <li>El juego es solo una forma de entretenimiento,
                     no reemplaza los ingresos de otras fuentes. Nunca apueste el dinero de su renta y el dinero que necesita para sus necesidades diarias.</li> <li>El juego es un juego de azar,
                     ganar no está garantizado en ninguna etapa.</li> <li>Asegúrese de que la decisión de apostar sea suya y que no haya sido influenciado por otros.</li> <li>Nunca trate de perseguir sus pérdidas. Verifique los montos que gasta regularmente.</li> <li>Asegúrese de conocer las reglas de los juegos que juega.</li> </ul>,
                    "La moderación es la clave para evitar la adicción al juego. ¡Tenga cuidado!"]
            },
            {
                "title": "BUSCA AYUDA",

                "content": ["Las siguientes preguntas lo ayudarán a comprender si tiene un problema de adicción:",
                    <ul> <li>¿Juegas para evitar enfrentarte a tu vida cotidiana?</li> <li>¿Faltas al trabajo o a la escuela para jugar?</li> <li>¿Has perdido interés en tu familia,
                     amigos o pasatiempos?</li> <li>¿Otras personas critican tus hábitos de juego?</li> <li>¿Juegas o apuestas todo tu dinero,
                     incluido el dinero que se supone que utilizarás para el alquiler y para pagar las facturas?</li> <li>¿Alguna vez has mentido para ocultar la cantidad de dinero o el tiempo que dedicas al juego?</li> <li>Cuando juegas y pierdes todo tu depósito,
                     ¿tienes la sensación de estar perdido y desesperado y tienes ganas de volver a jugar lo antes posible?</li> <li>¿Te sientes deprimido o incluso suicida debido al juego?</li> </ul>,
                    "Si respondiste que sí a una o más de las preguntas anteriores," +
                    " o si tienes alguna inquietud sobre la adicción al juego," +
                    " aquí hay algunas organizaciones a las que puedes dirigirte:",
                    <ul> <li>Gamblers Anonymous: <a href="http://www.gamblersanonymous.org/ga">http://www.gamblersanonymous.org/ga/ </a></li> <li>Gambling Therapy: <a href= "https://www.gamblingtherapy.org/en"> https://www.gamblingtherapy.org/en</a></li> <li>Global Gambling Guidance Group (G4): <a href= "https://www.gx4.com">https://www.gx4.com/</a></li> </ul>]
            },
            {
                "title": "AUTO LÍMITE",

                "content": ["Le recomendamos que utilice nuestra herramienta de auto límite y controle la cantidad de dinero que utiliza para apostar en nuestro sitio. Mediante una simple solicitud por escrito de nuestro equipo de soporte," +
                " estableceremos un límite en su cuenta.",
                    "Al establecer un límite," +
                    " tenga en cuenta con qué frecuencia juega y cuánto dura en cada sesión. Además," +
                    " decida de antemano qué cantidad es aceptable perder. Según su respuesta," +
                    " establezca un límite diario semanal y mensual.",
                    "En cualquier momento," +
                    " puede comunicarse con nuestro equipo de soporte en "+casino.support_email +" y solicitar un descanso por un máximo de seis semanas. En este período de tiempo," +
                    " limitaremos su acceso a la cuenta y a las cuentas nuevas si es que intenta abrir.",
                    "En cualquier momento," +
                    " puede recurrir a nuestro equipo de soporte y solicitar cerrar su cuenta por completo."]
            },
            {
                "title": "ATENCIÓN AL CLIENTE",

                "content": [<>Para cualquier consulta o asistencia adicional,
                 no dude en ponerse en contacto con nuestro equipo de Atención al Cliente en {casino.support_email}</>]
            }]
    },
    "it": {
        "sections": [{
            "title": "POLITICA DI GIOCO RESPONSABILE",

            "content": ["" + casino.name + " si dedica a fornire ai propri membri un ambiente di gioco responsabile. Attribuiamo grande importanza alla creazione di un ambiente sicuro per i nostri giocatori e alla prevenzione dell'uso compulsivo dei nostri prodotti di gioco," +
            " nonché all'accesso ai minorenni.",
                "Come società di gioco responsabile e regolamentata," +
                " rispettiamo tutte le linee guida e le normative necessarie.",
                "Per fare ciò," +
                " " + casino.name + " ha implementato le misure di sicurezza più avanzate," +
                " come segue:"]
        },
            {
                "title": "PROTEZIONE DEI MINORI",

                "content": ["Ai minorenni di età inferiore ai 18 anni (o all'età del consenso legale nella propria giurisdizione) è vietato giocare nei giochi online di " + casino.name + ". I nostri giochi da casinò online non sono progettati per bambini o adolescenti. Scoraggiamo attivamente e monitoriamo i minori di età inferiore ai 18 anni (o l'età del consenso legale nella tua giurisdizione) che tentano di giocare sui nostri siti Web e bloccando immediatamente il loro accesso.",
                    "Insieme a questo," +
                    " poiché i servizi Internet sono accessibili ovunque," +
                    " raccomandiamo ai genitori di installare software di protezione dei minori al fine di bloccare l'accesso dei minori ai siti Web di gioco d'azzardo.",
                    "Suggerimenti per i genitori:",
                    <ul> <li>Non lasciare il tuo computer incustodito quando il sito Web del casinò è aperto. Prestare particolare attenzione per garantire che il nostro servizio non sia accessibile ai bambini attraverso i diversi dispositivi elettronici.</li> <li>Proteggi i tuoi programmi di gioco con password e mantieni i tuoi dati di accesso fuori dalla portata dei bambini.</li> <li>Non consentire ai minori di età inferiore ai 18 anni (o all'età del consenso legale nella propria giurisdizione) di partecipare a qualsiasi attività di gioco d'azzardo.</li> <li>Informa i tuoi figli sull'illegalità e il potenziale danno del gioco d'azzardo minorile.</li> <li>Limita il tempo che i tuoi figli trascorrono online.</li> </ul>,
                    <>Importante: nessun software è privo di buchi. Se conosci un minore di età inferiore ai 18 anni (o l'età del consenso legale nella tua giurisdizione) che è registrato sul nostro sito,
                     ti preghiamo di avvisarci al più presto a {casino.support_email}</>]
            },
            {
                "title": "PREVENIRE IL GIOCO COMPULSIVO",

                "content": ["Mentre la maggior parte dei membri gioca per divertimento e intrattenimento," +
                " alcuni giocatori sviluppano dipendenza da giochi online. Perdere una notevole quantità di denaro a causa del gioco d'azzardo può portare a diversi problemi in tutte le principali aree della vita.",
                    "Prendiamo molto sul serio questo problema e quindi implementiamo le seguenti misure per aiutare a far fronte alla dipendenza:",
                    <ul> <li>I nostri dipendenti professionisti sono formati per essere in grado di rilevare il gioco d'azzardo compulsivo e il gioco d'azzardo minorile. Una volta rilevato,
                     vengono intraprese le azioni appropriate.</li> <li>Programma di autoesclusione che consente un modo semplice per richiedere la cancellazione dell'iscrizione. Poco dopo che la richiesta è stata ricevuta,
                     bloccheremo l'account del membro.</li> <li>Consentire all'utente di impostare il proprio limite di importo del deposito.</li> <li>Rimuovendoti dalla nostra lista e-mail su richiesta scritta.</li> </ul>,
                    "Importante:",
                    <ul> <li>La consulenza ha dimostrato di avere un notevole effetto positivo sulle persone che sono inclini al gioco compulsivo.</li> <li>Il gioco d'azzardo è solo una forma di intrattenimento,
                     non sostituisce le entrate provenienti da altre fonti. Non scommettere mai i soldi dell'affitto e i soldi di cui hai bisogno per le necessità quotidiane.</li> <li>Il gioco d'azzardo è un gioco di probabilita',
                     la vittoria non è garantita in nessuna fase.</li> <li>Assicurati che la decisione di giocare d'azzardo sia tua e che non sei stato influenzato da altri.</li> <li>Non tentare mai di inseguire le tue perdite. Controlla gli importi spesi su base regolare.</li> <li>Assicurati di conoscere le regole dei giochi che giochi.</li> </ul>,
                    "La moderazione è la chiave per evitare la dipendenza dal gioco d'azzardo. Sii consapevole."]
            },
            {
                "title": "CERCARE AIUTO",

                "content": ["Le seguenti domande ti aiuteranno a capire se hai un problema di dipendenza:",
                    "Giochi d'azzardo per evitare di affrontare la vita di tutti i giorni?",
                    <ul> <li>Giochi d'azzardo per evitare di affrontare la vita di tutti i giorni?</li> <li>Salti il lavoro o la scuola per giocare d'azzardo?</li> <li>Hai perso interesse per la tua famiglia,
                     i tuoi amici o i tuoi hobby?</li> <li>Le altre persone criticano le tue abitudini di gioco?</li> <li>Giochi d'azzardo tutti i tuoi soldi,
                     compresi i soldi che dovrebbero essere usati per l'affitto e le bollette?</li> <li>Hai mai mentito per coprire la quantità di denaro o il tempo che dedichi al gioco d'azzardo?</li> <li>Quando giochi e perdi tutto il tuo deposito,
                     hai la sensazione di aver perso e disperato e hai il bisogno di giocare di nuovo il più presto possibile?</li> <li>Ti senti depresso o addirittura suicida a causa del tuo gioco d'azzardo?</li> </ul>,
                    " Se hai risposto sì a una o più delle domande precedenti o se hai dubbi sulla dipendenza dal gioco d'azzardo," +
                    " ecco alcune organizzazioni a cui puoi rivolgerti:",
                    <ul> <li>Gamblers Anonymous: <a href="http://www.gamblersanonymous.org/ga">http://www.gamblersanonymous.org/ga/ </a></li> <li>Gambling Therapy: <a href= "https://www.gamblingtherapy.org/en"> https://www.gamblingtherapy.org/en</a></li> <li>Global Gambling Guidance Group (G4): <a href= "https://www.gx4.com">https://www.gx4.com/</a></li> </ul>]
            },
            {
                "title": "AUTO-LIMITE",

                "content": ["Ti consigliamo di utilizzare il nostro strumento di autolimitazione e controllare la quantità di denaro che utilizzi per giocare d'azzardo nel nostro sito. Con una semplice richiesta scritta del nostro team di supporto," +
                " imposteremo il limite sul tuo account.",
                    "Mentre imposti un limite," +
                    " tieni presente quanto spesso giochi e quanto dura ogni sessione. Inoltre," +
                    " decidere in anticipo qual è l'importo accettabile da perdere. In base alla tua risposta," +
                    " imposteremo un limite giornaliero," +
                    " settimanale e mensile.",
                    "In qualsiasi momento," +
                    " puoi contattare il nostro team di supporto all'indirizzo " + casino.support_email + " e richiedere di fare una pausa per un massimo di sei settimane. In questo periodo di tempo," +
                    " limiteremo il tuo accesso sul tuo conto e ai nuovi account che tenterai di aprire.",
                    "In qualsiasi momento," +
                    " puoi rivolgerti al nostro team di supporto e richiedere di chiudere completamente il tuo account."]
            },
            {
                "title": "SUPPORTO",

                "content": [<>Per ulteriore assistenza o domande,
                 non esitare a contattare il nostro team di supporto all'indirizzo {casino.support_email}</>]
            }]
    },
    "pt": {
        "sections": [{
        "title": "POLÍTICA DE JOGO RESPONSÁVEL",
        "content": ["A " + casino.name + " dedica-se a fornecer aos seus membros um ambiente de jogo responsável. Damos grande importância à criação de um ambiente seguro para os nossos jogadores e à prevenção do uso compulsivo dos nossos produtos de jogos, bem como ao acesso de menores de idade.",
        "Como empresa de jogos responsável e regulada, cumprimos todas as diretrizes e regulamentos necessários.",
        "Para tal, a " + casino.name + " implementou as medidas de segurança mais avançadas, conforme descrito abaixo:"]
},
    {
        "title": "PROTEÇÃO DE MENORES",

        "content": ["Menores de 18 anos (ou a idade de consentimento legal na sua jurisdição) não têm permissão para jogar nos jogos online do " + casino.name + ". Os nossos jogos de casino online não são destinados a crianças ou adolescentes. Desencorajamos ativamente e rastreamos menores de 18 anos (ou a idade de consentimento legal na sua jurisdição) que tentem jogar nos nossos websites e bloqueamos imediatamente o seu acesso.",
            "Além disso," +
            " como os serviços de internet são acessíveis em todo o lado," +
            " recomendamos aos pais que instalem software de proteção infantil para bloquear o acesso de menores a websites de jogos de azar.",
            "Dicas para os pais:",

            <ul>
                <li>Não deixe o seu computador desacompanhado quando o website do casino estiver em execução. Certifique-se de que o nosso serviço não é acessível a crianças através dos seus dispositivos.</li>
                <li>Proteja os seus programas de jogo com senha e mantenha os seus dados de login fora do alcance das crianças.</li>
                <li>Não permita que menores de 18 anos (ou a idade de consentimento legal na sua jurisdição) participem em qualquer atividade de jogo.</li>
                <li>Informe os seus filhos sobre a ilegalidade e os danos potenciais do jogo para menores de idade.</li>
                <li>Limite a quantidade de tempo que os seus filhos passam online.</li>
            </ul>,
            <>Importante: Nenhum software é totalmente seguro. Se você souber de um menor de 18 anos (ou a idade de consentimento legal na sua jurisdição) que esteja registrado no nosso site, por favor, avise-nos o mais rápido possível em {casino.email}</>]
    },
    {
        "title": "PREVENÇÃO DO JOGO COMPULSIVO",

        "content": ["Embora a maioria dos membros jogue por diversão e entretenimento," +
        " alguns jogadores desenvolvem vício em jogos online. Perder uma quantia significativa de dinheiro como resultado de jogos de azar pode levar a problemas em todas as áreas principais da vida.",
            "Levamos este assunto muito a sério e, por isso, implementamos as seguintes medidas para ajudar no combate ao vício:",
            <ul>
                <li>Nossos funcionários profissionais são treinados para detectar jogos compulsivos e jogos de azar por menores de idade. Quando detetados, são tomadas ações apropriadas.</li>
                <li>Programa de autoexclusão que permite solicitar facilmente o cancelamento da adesão. Pouco depois de recebermos o pedido, bloquearemos a conta do membro.</li>
                <li>Permitir ao membro definir o seu próprio limite de depósito.</li>
                <li>Remover o membro da nossa lista de e-mails mediante solicitação por escrito.</li>
            </ul>,
            "Importante:",
            <ul>
                <li>O aconselhamento mostrou ter um grande efeito positivo em pessoas propensas a jogos compulsivos.</li>
                <li>O jogo é apenas uma forma de entretenimento e não substitui o rendimento de outras fontes. Nunca aposte o dinheiro do seu aluguel e o dinheiro que você precisa para as necessidades diárias.</li>
                <li>O jogo é um jogo de azar, e não há garantia de ganhos em nenhuma etapa.</li>
                <li>Assegure-se de que a decisão de jogar seja sua e que não tenha sido influenciado por outros.</li>
                <li>Nunca tente recuperar as suas perdas. Verifique regularmente as quantias que gasta.</li>
                <li>Tenha certeza de que conhece as regras dos jogos que joga.</li>
            </ul>,
            "A moderação é a chave para evitar o vício em jogos de azar. Esteja consciente."]
    },
    {
        "title": "PROCURE AJUDA",

        "content": ["As seguintes perguntas ajudarão a entender se você tem um problema de vício:",

            <ul>
                <li>Você joga para evitar lidar com a sua vida cotidiana?</li>
                <li>Você falta ao trabalho ou à escola para jogar?</li>
                <li>Perdeu o interesse pela sua família, amigos ou hobbies?</li>
                <li>Outras pessoas criticam os seus hábitos de jogo?</li>
                <li>Você aposta todo o seu dinheiro, incluindo o dinheiro destinado ao aluguel e às contas?</li>
                <li>Já mentiu para encobrir a quantia de dinheiro ou tempo que gasta em jogos de azar?</li>
                <li>Quando aposta e perde todo o seu depósito, sente-se perdido e desesperado e sente vontade de jogar novamente o mais rápido possível?</li>
                <li>Você se sente deprimido ou até mesmo suicida por causa dos seus jogos de azar?</li>
            </ul>,
            "Se respondeu sim a uma ou mais das perguntas acima," +
            " ou se tem alguma preocupação com o vício em jogos de azar," +
            " aqui estão algumas organizações às quais pode recorrer:",
            <ul>
                <li>Jogadores Anônimos: <a
                    href="http://www.gamblersanonymous.org/ga">http://www.gamblersanonymous.org/ga/ </a></li>
                <li>Terapia para Jogos de Azar: <a
                    href='https://www.gamblingtherapy.org/en\'> https://www.gamblingtherapy.org/en</a></li>
                <li>Global Gambling Guidance Group (G4): <a href='https://www.gx4.com'>https://www.gx4.com/</a>
                </li>
            </ul>]
    },
    {
        "title": "AUTO-LIMITE",

        "content": ["Recomendamos que utilize a nossa ferramenta de auto-limite para controlar a quantidade de dinheiro que utiliza para jogar no nosso site. Com um simples pedido por escrito à nossa equipa de suporte," +
        " definiremos um limite na sua conta.",
            "Ao definir um limite," +
            " tenha em mente com que frequência joga e por quanto tempo cada sessão dura. Além disso," +
            " decida antecipadamente qual é o montante aceitável para perder. Com base na sua resposta," +
            " defina um limite diário," +
            " semanal e mensal.",
            "A qualquer momento," +
            " pode contactar a nossa equipa de suporte em "+casino.support_email +" e solicitar uma pausa de no máximo seis semanas. Durante este período," +
            " limitaremos o seu acesso à conta e a novas contas que tentar abrir.",
            "A qualquer momento," +
            " pode contactar a nossa equipa de suporte e solicitar o encerramento completo da sua conta."]
    },
    {
        "title": "SUPORTE",

        "content": ["Para qualquer assistência adicional ou dúvidas, por favor, não hesite em contactar a nossa equipa de suporte em " + casino.support_email + "."]
    }]
}

}
export default responsible