import React from "react";
import casino from "../config";
import { v4 as uuidv4 } from "uuid";
import casino_contents from "../contents";
import { langContext } from "../helper/langcontext";
import { useContext } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

export default function Terms() {
  const lang = useContext(langContext);
  const terms = casino_contents.terms[lang.language];
  const pages = casino.layout.footer.pages;
  const path = window.location.pathname;

    return (
    <>
      <Header/>
      <div className="middle_bg_pic"/>
      <div className="middle_content pages">
        <div className="pages_options">
          {pages.map((x) => {
            // console.log(x);
            return (
              <button
                key={uuidv4()}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = x.href;
                }}
                className={x.href === path ? "btn pg active" : "btn pg"}
              >
                {/* {console.log(x.href)} */}
                {x[lang.language]}
              </button>
            );
          })}
        </div>
        <div className="pages_contents">
          <div className="pages_content_wrapper">
            <h1>{terms.title}</h1>
            <div style={{marginBottom:"12px"}}>
              {terms.content.map((content, key) => (
                <p key={key}>{content}</p>
              ))}
            </div>
            {terms.sections.map((section, key) => (
              <div className={"collapse"} key={key}>
                <h4
                  onClick={(event) =>
                    event.target.parentElement.classList.toggle("open")
                  }
                >
                  {section.title}
                </h4>
                <div className={"p_content"}>
                  {section.content.map((content, key) => (
                    <p key={key}>{content}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
