import Cookies from "universal-cookie";

function getQueryVariable() {
    const urlParams = new URLSearchParams(window.location.search);
    const cookies = new Cookies({path: "/", maxAge: 31536000});
    const cs = {path: "/", maxAge: 31536000};
    const strictCheck = (varName) =>
        urlParams.has(varName) && urlParams.get(varName).trim() !== "";
    if (strictCheck("t")) cookies.set("tra_tracker", urlParams.get("t"));
    if (strictCheck("id"))
        cookies.set("tra_tracker", "00000_" + urlParams.get("id"),cs);
    if (strictCheck("tracker"))
        cookies.set("tra_tracker", urlParams.get("tracker"),cs);
    if (strictCheck("btag")) cookies.set("tra_btag", urlParams.get("btag"));
}
getQueryVariable();


let allCookies = document.cookie.split(";");
let trackerId = [];
let btag = [];
for (let i = 0; i < allCookies.length; i++) {
    const pair = allCookies[i].split("=");
    if (pair[0].trim() === "tra_tracker") {
        if (pair[1] !== "" || typeof pair[1] !== "undefined") {
            trackerId.splice(0, 1, pair[1]);
        }
    }
    if (pair[0].trim() === "tra_btag") {
        if (pair[1] !== "" || typeof pair[1] !== "undefined") {
            btag.splice(0, 1, pair[1]);
        }
    }
}

export const tracker = trackerId[0];
export const bannerTag = btag[0];
export const affiliate = tracker?tracker.split("_")[0]:undefined;

export const AffiliateGAOptions = affiliate ? {campaign_id: affiliate, affiliate_id: affiliate}:{};