import React, { useEffect, useState, useContext } from "react";
import Header from "./components/Header";
import Background from "./components/Background";
import MainSeparator from "./components/MainSeparator";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import "./style.css";
import internalJSON from "./lp_JSON/landingPages.json";
import { langContext } from "../../helper/langcontext";

function LandingPageManager() {
  let [width, setWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState();
  const { language } = useContext(langContext);
  const urlParams = window.location.pathname.split("/")[2];
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const id = urlParams;
    if (!id) window.location.href = "/";
    let landing = internalJSON.find((item) => {
      if (item.id === id) {
        setData(item);
        return item;
      }
      return false;
    });
    if (!landing) window.location.href = "/";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data && (
        <div
          style={{
            width: "100%",
            height: "100%",
            fontFamily: data.fonts ?? "Montserrat",
          }}
        >
          <Background
            background={
              width > 800 ? data?.background.img : data?.background.mobile_img
            }
          >
            <Modal
              modalOpen={modal}
              isOpenCb={(x) => {
                setModal(x);
              }}
              data={data}
            />
            <Header
              isOpenCb={(x) => {
                setModal(x);
              }}
              data={data}
            />
            <MainSeparator data={data} />
            <Footer data={data} />
          </Background>
        </div>
      )}
    </>
  );
}

export default LandingPageManager;
