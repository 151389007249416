import React from "react";
import Bonus from "./Bonus";

export default function Modal({ modalOpen, isOpenCb, data }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#000000d9",
        position: "absolute",
        top: 0,
        display: modalOpen ? "flex" : "none",
        backdropFilter: "blur(10px)",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button className="modal-btn" onClick={() => isOpenCb(false)}>
            X
          </button>{" "}
        </div>
        <div
          style={{
            maxWidth: "750px",
            minWidth: "350px",
            alignSelf: "center",
            marginTop: "80px",
          }}
        >
          <Bonus data={data["modal"]} />
        </div>
      </div>
    </div>
  );
}
