import React from "react";
import { useContext } from "react";
import { langContext } from "../../helper/langcontext";
import InnerHTML from "dangerously-set-html-content";
import Version3Chat from "./v3/version3Chat";

export default function LiveChat({ callback }) {
  const lang = useContext(langContext);
  const [chat, setChat] = React.useState(true);

  return (
    <div id="live_chat" className="live_chat">
      <div
        className="chat_cancel"
        onClick={() => {
          setChat(x=>!x);
          setTimeout(() => {
            callback(false);
          }, 500);
          
        }}
      ></div>
         <Version3Chat cb={chat}/>
    </div>
  );
}

{
  /*   <script type="text/javascript">
  var request = {}
  request.chatCredentialUrl = 'https://casinocontroller.com/avantgarde/engine/Chat/LiveChatService.php';
  cs = new ChatSetup();
  cs.setupChatStub(request,'cw');
  </script> */
}
