import React from "react";
import {useState, useEffect, useRef} from "react";
import casino from "../../config";
import {pages, btn} from "../../languages/buttons";
import {v4 as uuidv4} from 'uuid';
import {useContext} from "react";
import {langContext} from "../../helper/langcontext";
import {Squash as Hamburger} from 'hamburger-react'
import Game_separator from "../game_separator/game_separator";
import LiveChat from "../LiveChat/LiveChat";
import LanguageOptions from "../languageOptions/LanguageOptions";


export default function Header() {
    const lang = useContext(langContext)
    const header_pages = casino.layout.header.header_pages;
    const side_bar = casino.layout.header.side_bar;
    const footer_top = casino.layout.footer.footer_top;
    const burger_color = casino.layout.header.burger_menu_color;
    const [isOpen, setOpen] = useState(false)
    let [width, setWidth] = useState(window.innerWidth);
    let [stringForFilter, setStringForFilter] = useState("")
    const [languageOptions, setLanguageOptions] = useState(false);
    const [LiveChatHandler, setLiveChatHandler] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    useEffect(() => {
        if(languageOptions){
            setLanguageOptions(false)
        }
    }, [lang])

    const [filterDiv, setFilterDiv] = useState(false)
    const headerButtonRender = () => {
        return header_pages.map((x) => {
            return (
                <button key={uuidv4()}
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = x.href;
                        }}
                        className="btn header"
                >
                    {x[lang.language]}
                </button>
            );
        })
    }
    const SideCollectionRender = () => {
        return Object.entries(side_bar).map(([f, s]) => {
            // console.log(f,s)
            return (
                <div className="side_bar_section">
                    {s.map(x => {
                        return (
                            <>
                                <button key={uuidv4()}
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = x.href;
                                        }}
                                        className="btn header"
                                >

                                    {x[lang.language]}
                                    <img style={{marginRight: "10px"}} src={x.icon}/>
                                </button>
                            </>)
                    })}
                </div>
            )
        })
    }
    // SideCollectionRender()
    const languagePictureHandler = () => {
        switch (lang.language) {
            case "en":
                return "/images/lang_images/en.svg";
                break;
            case "de":
                return "/images/lang_images/de.svg";
                break;
            case "es":
                return "/images/lang_images/es.svg";
                break;
            case "fr":
                return "/images/lang_images/fr.svg";
                break;
            case "it":
                return "/images/lang_images/it.svg";
                break;
            case "pt":
                return "/images/lang_images/pt.svg";
                break;

            default:
                return "/images/lang_images/en.svg";
        }
    }
    const element = document.querySelector(".burger-options");
    document.addEventListener("mousedown", (e) => {
        if (element && !element.contains(e.target)) setOpen(false)
    });
    return (
        <>
            {LiveChatHandler && (
                <LiveChat callback={(value) => setLiveChatHandler(value)}/>
            )}
            {languageOptions && (
                <LanguageOptions
                    callback={(value) => setLanguageOptions(value)}
                />
            )}
            {/*//////////////////////////////////////////////////////////////////*/}

            <div className="header-component">

                <div className="header-left">
                    <Hamburger toggled={isOpen} toggle={setOpen} color={burger_color} size={20}/>
                    <div className={isOpen ? "bg-fixed active" : "bg-fixed"}/>
                    <div className={isOpen ? "burger-options active" : "burger-options"}>

                        <div className="burger-options-header">
                            <Hamburger toggled={isOpen} toggle={setOpen} color={burger_color} size={20}/>
                            <div className="options-header-logo"></div>
                        </div>
                        <div className="side_bar_section login">
                            <div className="header-right">

                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = pages.login.href;
                                    }}
                                    className="btn header "
                                >
                                    {pages.login[lang.language]}
                                </button>

                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = pages.join.href;
                                    }}
                                    className="btn header st"
                                >
                                    {pages.join[lang.language]}
                                </button>
                            </div>
                        </div>

                        {/*//////////////////////////////////////*/}
                        <div className="slider_bottom">
                            <div
                                className="live_chat_btn"
                                onClick={() => {
                                    LiveChatHandler
                                        ? setLiveChatHandler(false)
                                        : setLiveChatHandler(true);
                                }}
                            >
                                <span className="ft-imgs chat"/>
                                <button className="btn chat"/>
                            </div>
                            <div
                                onClick={() => {
                                    languageOptions
                                        ? setLanguageOptions(false)
                                        : setLanguageOptions(true);
                                }}
                                // this is the language button, the class name because of css
                                className="live_chat_btn"
                            >
                                <img className="ft-imgs" src={languagePictureHandler()}/>

                            </div>
                        </div>
                        <div className='slide_pages_container'>
                            {SideCollectionRender()}


                            {/*//////////CHRISTMAS////////////*/}
                            {/*<div className="side_bar_section">*/}
                            {/*<button key={uuidv4()}*/}
                            {/*        type="button"*/}
                            {/*        onClick={(e) => {*/}
                            {/*            e.preventDefault();*/}
                            {/*            window.location.href = "/xmas";*/}
                            {/*        }}*/}
                            {/*        className="btn header"*/}
                            {/*>*/}

                            {/*    Christmas*/}
                            {/*    <img style={{marginRight: "10px"}} />*/}
                            {/*</button>*/}
                            {/*</div>*/}
                            {/*//////////CHRISTMAS////////////*/}


                        </div>

                        {/*////////////////////////////////////////////////////////////////////*/}
                    </div>
                    <div className="header-pages">
                        {headerButtonRender()}
                    </div>
                </div>
                <div className={isOpen ? "slide active" : "slide"}>

                </div>
                <div onClick={() => {
                    window.location.href = "/"
                }} className="header-logo"/>
                <div className="mobil-header-right">

                    <div className="header-right">
                        {width > 600 && <div
                            onClick={() => {
                                languageOptions
                                    ? setLanguageOptions(false)
                                    : setLanguageOptions(true);
                            }}
                            // this is the language button, the class name because of css
                            className="live_chat_btn"
                            style={{height: "35px", width: "45px", borderRadius: '5px'}}
                        >
                            <img className="ft-imgs" src={languagePictureHandler()}/>

                        </div>}
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = pages.login.href;
                            }}
                            className="btn header "
                        >
                            {pages.login[lang.language]}
                        </button>

                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = pages.join.href;
                            }}
                            className="btn header st"
                        >
                            {pages.join[lang.language]}
                        </button>
                    </div>
                </div>
                <div className="filter_button" onClick={() => {
                    setFilterDiv(x => !x)
                }}/>
            </div>
            {filterDiv &&
                <div className={filterDiv ? "filter_div active" : "filter_div"}>
                    <div className="filter_header">
                        <div className="filter_button inp"/>
                        <input onChange={(e) => {
                            setStringForFilter(e.target.value)
                        }} placeholder="Games,providers,categories"/>
                        <div className="filter_button close" onClick={() => {
                            setFilterDiv(x => !x)
                        }}>
                        </div>
                    </div>

                    <Game_separator comingString={stringForFilter} method={"SEARCH"}/>

                </div>}
        </>
    );
}
