import React, {useContext} from "react";
import {langContext} from "../helper/langcontext";
import casino from "../config";
import {AffiliateGAOptions, bannerTag, tracker} from "../affiliate";

export default function Instantplay(props) {
    const lang = useContext(langContext);

    const checkReg = (x) => {
        if (x !== undefined) {
            return x;
        }
        return "";
    };

    let casinocontroller =
        "//www.casinocontroller.com/" +
        casino.rival.sortName +
        "/engine/EmbedGame/EmbedGame.php?";
    let mode = "mode=lobby&";
    let langUrl = "lang=" + lang.language + "&";
    let bTag = bannerTag ? `btag=${bannerTag}&` : "";
    let T = tracker ? `t=${tracker}&tracker=${tracker}&` : "";
    let id = "id=" + casino.rival.id + "&";
    // let returnUrl ="returnUrl=//localhost:3000";
    let returnUrl = "returnUrl=//" + window.location.host;
    const URL =
        casinocontroller +
        mode +
        langUrl +
        bTag +
        T +
        id +
        returnUrl +
        checkReg(props.extraUrlParams);

    window.onmessage = (e) => {
        // console.log(e);
        if (e.origin.includes("casinocontroller")) {
            switch (e.data?.action) {
                case "USER_LOGIN_SUCCESS":
                    window.gtag('event', 'login', {user_id: e.data?.id, ...AffiliateGAOptions});
                    break;

                case "GAMES_START_GAME":
                    window.gtag('event', 'play', {game_id: e.data?.id, ...AffiliateGAOptions});
                    break;

                case 'USER_UPDATE_BALANCE':
                    window.gtag('event', 'balance', {user_balance: e.data?.balance, ...AffiliateGAOptions});
                    break;

                case 'USER_TOGGLE_CASHIER':
                    window.gtag('event', 'cashier', {cashier_visible: e.data?.show, ...AffiliateGAOptions});
                    break;

                case 'USER_LOGOUT_SUCCESS':
                    window.gtag('event', 'logout', AffiliateGAOptions);
                    break;

                case 'REG_SET_PAGE':
                    window.gtag('event', 'register_page_' + e.data?.page, ...AffiliateGAOptions);
                    break;

                case 'REG_LOADED':
                    window.gtag('event', 'register_page_loaded', {play_mode: e.data?.mode, ...AffiliateGAOptions});
                    break;

                case "REG_SUBMIT":
                    window.gtag('event', 'register_submit', AffiliateGAOptions);
                    break;

                case "REG_COMPLETE":
                    window.gtag('event', 'register_complete', {user_id: e.data?.id, ...AffiliateGAOptions});
                    break;

                case "PAGE_LOAD":
                    window.gtag('event', 'page_load', AffiliateGAOptions);
                    break;

                case "REG_ERROR":
                    window.gtag('event', 'register_error', AffiliateGAOptions);
                    break;

                default:
                    break;
            }
        }
    };

    return (
        <iframe
            title={"casino"}
            src={URL}
            style={{width: "100%", height: "100vh", zIndex: "9999999999"}}
            frameBorder="0"
            scrolling="No"
            id="embedded_game"
            name="embedded_game"
        ></iframe>
    );
}
