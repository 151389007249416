import x from "./games.json"

const {games, categories, game_feature_keys, publishers} = x

export const games_data = games.map(game => {
    // add publisher names to the games
    game.publisher_name = publishers[game.publisher_id];
    // add category names to the games
    game.category_name = categories.find(c => c.category_id = game.category_id).name;
    // console.log(game);
    return game
})
