import React,{useContext} from "react";
import { langContext } from "../../../helper/langcontext";



export default function Bonus({ data }) {
  const { language } = useContext(langContext);
  return (
    <div className="bonus-container">
      {data &&
        data.map((item, index) => {
          if (item.place) return null;
          if (item.img)
            return (
              <img
                key={index}
                src={item.img}
                alt="img"
                style={{
                  minWidth: "220px",
                  maxWidth: "400px",
                  maxHeight: "150px",
                  marginTop: item.top,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            );
          if (item.btn)
            return (
              <button
                key={index}
                className="btn-lp"
                onClick={() => {
                  //replace with your own link
                  window.location.href = item?.btn?.url;
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor =
                    item.btn.bgHover ?? "#ffffff";
                  e.target.style.color = item.btn.fontHoverColor ?? "#000000";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = item.btn.bg ?? "#000000";
                  e.target.style.color = item.btn.fontColor ?? "#ffffff";
                }}
                style={{
                  color: item.btn.fontColor,
                  marginTop: item.top,
                  backgroundColor: item.btn.bg,
                  border: `2px solid ${item.btn.border}`,
                }}
              >
                {item.btn["text"][language]}
              </button>
            );
          return (
            <div
              key={index}
              className="bonus-text"
              style={{ marginTop: item.top }}
            >
              <span
                style={{
                  color: item.color,
                  fontSize: "max(" + item.size + " ,20px)",
                  fontWeight: item.fontWeight,
                }}
              >
                {item.text[language]}
              </span>
            </div>
          );
        })}
    </div>
  );
}
