import React from "react";
import casino from "../../config";
const bonus = {
    "en": {
        "sections": [{
            "title": "Welcome Bonus - 555% BTC Deposit Bonus",
            "content": ["Minimum wagering: 35x times playthrough on deposit + bonus.", "Excluded country list casino players: Canada, Israel, Costa Rica.", "Excluded country list using deposit bonuses: Bulgaria, Costa Rica, Georgia, Latvia, Russia, Ukraine.", "Min Deposit: €/$ 25", "Max Deposit: €/$ 375", "Players must meet the wagering requirements before they can cash out. This bonus cannot be used to play table and card games or video poker and slot progressive games."]
        }]
    }, "fr": {
        "sections": [{
            "title": "Bonus De Bienvenue - 555% BTC Bonus De Dépôt",
            "content": ["Mise minimum: Cumul de mises de 35X fois du dépôt + bonus.", "Liste des pays les joueurs de casino exclus: le Canada, Israël, le Costa Rica.", "Liste des pays les joueurs de casino exclus: le Canada, Israël, le Costa Rica, Royaume Uni.", "Depot minimum €/$ 25", "Depot maximum: €/$ 375", "Montant du bonus encaissable: Pas de maximum. retirez entierement vos gains, mais l'argent du bonus même sera déduit.", "Les joueurs doivent complter les exigences de mise avant de pouvoir encaisser. Ce bonus ne peut pas être utilisé pour jouer aux jeux de table et aux jeux de cartes ainsi que le vidéo poker et aux slots progressistes.", ""]
        }]
    }, "de": {
        "sections": [{
            "title": "Willkommensbonus - 555% BTC Einzahlungsbonus",
            "content": ["Mindestwetteinsatz: 35x Spieldurchlauf der Einzahlung + Bonus.", "Ausgeschlossene Spieler nach Land: Canada, Israel, Costa Rica, UK.", "Ausgeschlossene Spieler für Einzahlungsbonusse nach Land: Bulgarien, Costa Rica, Georgien, Lettland, Russland, Ukraine.", "Mindesteinzahlung: €/$ 25", "Maximaleinzahlung: €/$ 375", "Spieler müssen die Spielanforderungen erfüllen, bevor eine Auszahlung beantragt werden kann. Dieser Bonus kann nicht für das Spielen von Brett-und Kartenspielen, sowie Video-Kartenspiele und Slot Progressive verwendet werden.", "", ""]
        }]
    }, "es": {
        "sections": [{
            "title": "Bono De Bienvenida - 555% BTC Bono De Depósito",
            "content": ["Apuesta mínima: 35x veces de apuesta depósito + bono.", "Lista de países excluidos del casinos: Canadá, Israel, Costa Rica.", "Lista de países excluidos mediante bonos de depósito: Bulgaria, Costa Rica, Georgia, Letonia, Rusia, Ucrania.", "Depósito mínimo: €/$ 25", "Depósito máximo: €/$ 375", "Los jugadores deben cumplir con los requisitos de apuesta antes de poder retirar el dinero. Este bono no se puede utilizar para jugar juegos de mesa, cartas, video poker o juegos de tragamonedas progresivas.", ""]
        }]
    }, "it": {
        "sections": [{
            "title": "Bonus Di Benvenuto - 555% BTC Bonus Di Deposito",
            "content": ["Scommessa minima: Volume di gioco 35X volte il deposito + bonus.", "Lista dei paesi di giocatori di casinò esclusi: Canada, Israele, Costa Rica.", "Lista dei paesi esclusi dei bonus su deposito: Bulgaria, Costa Rica, Georgia, Lettonia, Russia, Ucraina.", "Deposito minimo €/₤/$ 25", "Deposito massimo: €/₤/$ 375", "Importo del bonus incassabile: Senza massimo. Ritirate tutte le vostre vincite, ma il denaro del bonus verrà detratto.", "I giocatori devono soddisfare i requisiti di scommessa prima di poter incassare. Questo bonus non può essere utilizzato per giocare a giochi da tavolo e di carte o video poker e giochi di slot progressive."]
        }]
    },
    "pt": {
        "sections": [{
            "title": "Bônus de Boas-Vindas - Bônus de Depósito de 555% em BTC",
            "content": ["Apostas mínimas: 35 vezes o valor do depósito + bônus.", "Lista de países excluídos para jogadores do cassino: Canadá, Israel, Costa Rica.", "Lista de países excluídos para uso de bônus de depósito: Bulgária, Costa Rica, Geórgia, Letônia, Rússia, Ucrânia.", "Depósito Mínimo: €/$ 25", "Depósito Máximo: €/$ 375", "Os jogadores devem cumprir os requisitos de apostas antes de poderem sacar. Este bônus não pode ser usado para jogar jogos de mesa, cartas, video poker ou jogos progressivos de caça-níqueis."]
        }]
    }
    
}
export default bonus;