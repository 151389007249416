import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import App from './App';
import Cookies from 'universal-cookie';
import casino from './config';

function getQueryVariable() {
    const urlParams = new URLSearchParams(window.location.search)
    const cookies = new Cookies();
    const strictCheck = varName => urlParams.has(varName) && urlParams.get(varName).trim() !== ''
    if (strictCheck("t")) cookies.set('tra_tracker', urlParams.get("t"));
    if (strictCheck("id")) cookies.set('tra_tracker', "00000_" + urlParams.get("id"));
    if (strictCheck("tracker")) cookies.set('tra_tracker', urlParams.get("tracker"));
    if (strictCheck("btag")) cookies.set('tra_btag', urlParams.get("btag"));
}

getQueryVariable();

let allCookies = document.cookie.split(';')
let trackerId = [];
let btag = [];
for (let i = 0; i < allCookies.length; i++) {
    const pair = allCookies[i].split('=');
    if (pair[0] === " tra_tracker") {
        if (pair[1] !== '' || typeof pair[1] !== "undefined") {
            trackerId.splice(0, 1, pair[1]);
        }
    }
    if (pair[0] === " tra_btag") {
        if (pair[1] !== '' || typeof pair[1] !== "undefined") {
            btag.splice(0, 1, pair[1]);
        }
    }
}

let bTag = btag[0] ? `btag=${btag[0]}&` : '';
//let gambollaURL = `https://r.gambolla.com/script?c=${casino.id}&mode=lobby&lang=en&game_id=&${bTag}&anon=&returnUrl=&t=${trackerId[0] || ''}`


ReactDOM.render(<App />, document.getElementById("root"));

// hose any existing service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
        .then((registration) => {
            registration.unregister();
        })
        .catch((error) => {
            console.error(error.message);
        });
}