import React, {useEffect, useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useContext } from "react";
import { langContext } from "../../helper/langcontext";
import casino from "../../config";
const languagesFromConfig = casino.languages;
export default function LanguageOptions({ callback }) {
  const lang = useContext(langContext);
  useEffect(() => {
    localStorage.setItem("language", lang.language);
  }, [lang]);
  const [close,setClose] = useState(false)

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        callback(false);
      }}
    >
      <div className="language-options">
        {languagesFromConfig.map((x, key) => {
          return (
            <div key={key} className="langOptionsEachWrapper">
              <div
                className={
                  lang.language === x.value ? "langOption active" : "langOption"
                }
                onClick={() => {
                  lang.langSetFunc(x.value);
                }}
              >
                <img src={x.imagePath} />
              </div>
              <span>{x.name}</span>
            </div>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
}
