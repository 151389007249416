import React from "react";
import casino from "../config";
import { v4 as uuidv4 } from "uuid";
import casino_contents from "../contents";
import { langContext } from "../helper/langcontext";
import { useContext } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";



export default function Aml() {
    const lang = useContext(langContext)
    const aml = casino_contents.aml[lang.language];
    const pages = casino.layout.footer.pages;
    const path = window.location.pathname
    // console.log(aml)
    return (
      <>
          <Header/>
      <div className="middle_bg_pic"/>
      <div className="middle_content pages">
      <div className="pages_options">
        {pages.map((x) => {
          return (
            <button
            key={uuidv4()}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = x.href;
            }}
            className={x.href === path ? "btn pg active" : "btn pg"}
            >
             {/*{ console.log(x.href)}*/}
              {x[lang.language]}
            </button>
          );
        })}
      </div>
      <div className="pages_contents">
      <div className="pages_content_wrapper">
        <h1>{aml.title}</h1>
        {aml.sections.map((x, key) => {
          return (
            <div key={key}>
              <h2 key={key}>{x.title}</h2>
              {x.content.map( (c ,key )=> <p key={key}>{c}</p>)}
            </div>
          );
        })}
      </div>
      </div>
    </div>
          <Footer/>
    </>
  );
}
