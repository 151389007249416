//export all footer logos

import cashlib from "./cashlib.png";
import giropay from "./giropay.png";
import mastercard from "./mastercard.png";
import neteller from "./neteller.png";
import paysafe from "./paysafe.png";
import postpay from "./postpay.png";
import skrill from "./skrill.png";
import ukash from "./ukash.png";
import upay from "./upay.png";
import visa from "./visa.png";
import eighteenplus from "./18+.svg";
import android from "./android.svg";
import betsoft from "./betsoft.svg";
import dragongaming from "./dragongaming.svg";
import iPhone from "./iPhone.svg";
import microsoft from "./microsoft.svg";
import rival from "./rival.svg";
import saucify from "./saucify.svg";
import vivogaming from "./vivogaming.svg";

export const footerLogos = {
  cashlib: cashlib,
  giropay: giropay,
  mastercard: mastercard,
  neteller: neteller,
  paysafe: paysafe,
  postpay: postpay,
  skrill: skrill,
  ukash: ukash,
  upay: upay,
  visa: visa,
  eighteenplus: eighteenplus,
  android: android,
  betsoft: betsoft,
  dragongaming: dragongaming,
  iPhone: iPhone,
  microsoft: microsoft,
  rival: rival,
  saucify: saucify,
  vivogaming: vivogaming,

};
