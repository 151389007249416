import React, { useState, useEffect } from "react";
import { ChatClient } from "./ChatClient.min";
import casino from "../../../config";
import "./chat.css";

let chatclient = new ChatClient();

export default function Version3Chat({cb}) {
  const brandName = casino.rival.chatName;
  const chatServer = "chat-v3.casinocontroller.com";
  let playerLocation = "Website";
  let hash = null;
  const credentialService = null;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  if (credentialService) {
    chatclient.useCredentialService(credentialService);
  }

  useEffect(() => {
    showchatclient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Change some text when a message is received */
  chatclient.onMessage = (userName, message, isUser) => {
    setMessages((messages) => [
      ...messages,
      { isuser: isUser, username: userName, message: message },
    ]);
  };

  /* Change some text when the chat socket connects */
  chatclient.onAvailabilityChange = (available) => {
    document.getElementById("availability").innerHTML = available
      ? setMessages((messages) => [
          ...messages,
          {
            isuser: false,
            username: "Customer Service",
            message: "We are here to help.",
          },
        ])
      : setMessages((messages) => [
        ...messages,
        {
          isuser: false,
          username: 'Customer Service',
          message: "We're not available right now. Please try again later.",
        },
      ]);
  };

  /* Change some text when the chat socket connects */
  chatclient.onDisconnect = () => {};
  /* Show a greyed out text notice about customer service joining */
  chatclient.onJoin = (userName, isUser) => {
    if (isUser) {
      return;
    }
    setMessages((messages) => [
      ...messages,
      {
        isuser: false,
        username: userName,
        message: "has joined the conversation.",
      },
    ]);
  };

  /* Show a greyed out text notice about customer service leaving */
  chatclient.onLeave = (userName, isUser) => {
    if (isUser) {
      return;
    }
    setMessages((messages) => [
      ...messages,
      {
        isuser: false,
        username: userName,
        message: "has left the conversation.",
      },
    ]);
  };

  /* Initialize the full chat client, and set up event listeners */
  const showchatclient = () => {
    if(!chatclient.websocket)
    chatclient.initialize(
      "wss://" + chatServer + "/",
      brandName,
      playerLocation,
      hash
    );
  };

  /* Action performed when a user submits a message */
  const onFullSubmit = (input) => {
    chatclient.sendMessage(input);
    setInputValue("");
  };

  const ref = useChatScroll(messages)
  function useChatScroll() {
    const ref = React.useRef();
    React.useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [messages]);
    return ref;
  }

  return (
    <>
      <div className="chat_c_wrapper">
        <div ref={ref} className="message_wrapper">
        {messages.length > 0 &&
          messages.map((message, index) => {
            return (
              <div
                className={
                  message?.isuser
                    ? "chat_conversation_user"
                    : "chat_conversation_service"
                }
                key={index}
              >
                <div className="chat_conversation_name">{message.username}</div>
                <div className="chat_conversation_message">
                  {message.message}
                </div>
              </div>
            );
          })}
          </div>
        <div className="input_space">
          <input
            type="text"
            value={inputValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onFullSubmit(inputValue);
              }
            }}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <button
            onClick={() => {
              onFullSubmit(inputValue);
            }}
          >
            Send
          </button>
        </div>
      </div>
    </>
  );
}
