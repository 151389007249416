import React from 'react'
import {langContext} from "../../helper/langcontext";
import {useContext} from "react";

export default function Modal({data,onRequestOpen, onRequestClose}) {
    const lang = useContext(langContext);
    return (
    <div className={onRequestOpen? "modal_wrapper active":"modal_wrapper"}>
      <div className="modal_container">
        <span className="modal_close" onClick={(()=>{onRequestClose(x=>!x)})}/>
       {/*<h1>{data?.name?.[lang.language]}</h1>*/}
       <div className="pages_contents">
         {data?.details?.[lang.language]}
       </div>
      </div>
    </div>
  )
}
