import React, {useEffect} from "react";
import Slider from "../components/slider/slider";
import {useContext} from "react";
import {langContext} from "../helper/langcontext";
import Bonus_slider from "../components/bonus_slider/bonus_slider";
import Search_bar from "../components/search_bar/search_bar"
import Jackpot_slider from "../components/Jakpot_slider/jacpot_slider";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import {pages} from "../languages/buttons";


// const snowDivs = (num) => [...Array(num - 1).keys()].map(() => <div className="snowflake"/>) // snowflake


function Games() {
    let path = window.location.pathname
    const lang = useContext(langContext);

    return (
        <>
            <Header/>
            {/* {snowDivs(50)} */}
            <div className="games_wrapper"
                 style={{
                     height: "auto",
                     // marginTop: "70px",
                     width: "100%",
                     alignSelf: "center",
                     zIndex: "0",
                 }}
            >
                <Bonus_slider path={path}/>
                <Search_bar/>

                <Jackpot_slider/>
                <div className="slider-wrapper">
                    <div className="slider-title">
          <span>{pages.games_1[lang.language]}
          </span>
                    </div>

                    <Slider id={"popular"} number={1}/>
                </div>


                <div className="slider-wrapper">
                    <div className="slider-title">
                        <span> {pages.new_games[lang.language]}</span>
                    </div>
                    <Slider id={"new"} number={2}/>
                </div>


                <div className="slider-wrapper">
                    <div className="slider-title">
                        <span> {pages.slots[lang.language]}</span>
                    </div>
                    <Slider id={34} number={3}/>
                </div>

                <div className="slider-wrapper">
                    <div className="slider-title">
                        <span> {pages.games_4[lang.language]}</span>
                    </div>
                    <Slider id={35} number={4}/>
                </div>


            </div>
            <Footer/>
        </>
    );
}

export default Games;
