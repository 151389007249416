import React from 'react'
import {templates} from '../templates/templates'

export default function Footer({data = 'pure'}) {
 
 const footers = templates[data.footer]['footer'] ?? []
  return (
    <div className='footer' style={{

    }}>
      <div className='footer-container' style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {footers.map((image, index) => {
          return (
            <img key={index} src={image} alt='img' style={{
              minWidth: '50px',
              width: '5vw',
              maxWidth: '80px',
              aspectRatio: '1/1',
              height: '30px',
              margin: '10px',
              objectFit: 'contain',
            }}/>
          )
        })}
    </div>
    </div>
  )
}
