/* eslint-disable no-unused-expressions */
var ChatMethod,ChatEvent,__awaiter =
    (this && this.__awaiter) ||
    function (e, o, l, h) {
      return new (l = l || Promise)(function (n, t) {
        function i(e) {
          try {
            a(h.next(e));
          } catch (e) {
            t(e);
          }
        }
        function s(e) {
          try {
            a(h.throw(e));
          } catch (e) {
            t(e);
          }
        }
        function a(e) {
          var t;
          e.done
            ? n(e.value)
            : ((t = e.value) instanceof l
                ? t
                : new l(function (e) {
                    e(t);
                  })
              ).then(i, s);
        }
        a((h = h.apply(e, o || [])).next());
      });
    };
    
class User {}
class Channel {}
export class Credentials {
  constructor(e = "", t, n) {
    (this.group = e), (this.session = t), (this.hmac = n);
  }
}

!(function (e) {
  (e.login = "login"),
    (e.joinInitialChannel = "joinInitialChannel"),
    (e.setLocation = "setLocation"),
    (e.sendMessage = "sendMessage"),
    (e.getAvailability = "getAvailability"),
    (e.requestSupport = "requestSupport");
})((ChatMethod = ChatMethod || {})),
  (function (e) {
    (e.receivedMessage = "receivedMessage"),
      (e.userLeftChannel = "userLeftChannel"),
      (e.userJoinedChannel = "userJoinedChannel");
  })((ChatEvent = ChatEvent || {}));

export class ChatClient {
  constructor() {
    this.requestSupport = !0;
  }
  onMessage(e, t, n) {
    console.warn("ChatClient.onMessage is not implemented");
  }
  onDisconnect() {
    console.debug("ChatClient.onDisconnect is not implemented");
  }
  onJoin(e, t) {
    console.debug("ChatClient.onJoin is not implemented");
  }
  onLeave(e, t) {
    console.debug("ChatClient.onLeave is not implemented");
  }
  onAvailabilityChange(e) {
    console.log("ChatClient.onAvailabilityChange is not implemented");
  }


  initialize(e, t, n, i) {
    if (this.websocket) throw new Error("ChatClient: Already initialized");
    (this.location = n), (this.websocket = new WebSocket(e));
    const s = () => {
      this.setAvailability(!1),
        this.onDisconnect(),
        this.websocket &&
          this.websocket.readyState === WebSocket.OPEN &&
          this.websocket.close(),
        (this.websocket = void 0),
        this.pingInterval &&
          (clearInterval(this.pingInterval), (this.pingInterval = void 0)),
        this.availInterval &&
          (clearInterval(this.availInterval), (this.availInterval = void 0));
    };
    return (
      (this.websocket.onopen = () =>
        __awaiter(this, void 0, void 0, function* () {
          var e = yield this.login(t, i);
          this.send("login", e, e.hmac),
            (this.pingInterval = setInterval(
              () => this.ping(),
              1e3 * ChatClient.INTERVAL_PING
            )),
            (this.availInterval = setInterval(
              () => this.getAvailability(),
              1e3 * ChatClient.INTERVAL_AVAIL
            ));
        })),
      (this.websocket.onclose = () => {
        s();
      }),
      (this.websocket.onerror = (e) => {
        this.websocket && this.websocket.readyState !== WebSocket.OPEN && s(),
          console.warn("ChatClient: WebSocket error:", e);
      }),
      (this.websocket.onmessage = (t) => {
        let e;
        try {
          e = JSON.parse(t.data);
        } catch (e) {
          return void console.error("ChatClient: JSON parse error:", t.data, e);
        }
        e.event
          ? this.handleEvent(e.event, e.result)
          : e.id
          ? this.handleResponse(e.id, e.result)
          : console.debug("ChatClient: Unhandled websocket message", e);
      }),
      this
    );
  }
  sendMessage(e) {
    return (
      this.channel
        ? (this.send(ChatMethod.sendMessage, {
            id: this.channel.id,
            message: e,
          }),
          this.requestSupport &&
            (this.send(ChatMethod.requestSupport, { id: this.channel.id }),
            (this.requestSupport = !1)))
        : console.warn(
            "ChatClient.sendMessage: Cannot send message: Not yet connected"
          ),
      this
    );
  }
  setLocation(e) {
    return (
      this.send(ChatMethod.setLocation, { location: e }),
      (this.location = e),
      this
    );
  }
  useCredentialService(e) {
    return (this.credentialServiceURL = e), this;
  }
  handleEvent(e, t) {
    var n;
    switch (e) {
      case ChatEvent.receivedMessage:
        this.onMessage(
          t.user.username,
          t.message,
          (null === (n = t.user) || void 0 === n ? void 0 : n.username) ===
            (null === (n = this.user) || void 0 === n ? void 0 : n.username)
        );
        break;
      case ChatEvent.userLeftChannel:
        (this.requestSupport = !0),
          this.onLeave(
            null === (n = t.user) || void 0 === n ? void 0 : n.username,
            (null === (n = t.user) || void 0 === n ? void 0 : n.username) ===
              (null === (n = this.user) || void 0 === n ? void 0 : n.username)
          );
        break;
      case ChatEvent.userJoinedChannel:
        this.onJoin(
          null === (n = t.user) || void 0 === n ? void 0 : n.username,
          (null === (n = t.user) || void 0 === n ? void 0 : n.username) ===
            (null === (n = this.user) || void 0 === n ? void 0 : n.username)
        );
    }
  }
  handleResponse(e, t) {
    var n;
    switch (e) {
      case ChatMethod.login:
        (this.user = t.user), this.send(ChatMethod.joinInitialChannel, {});
        break;
      case ChatMethod.joinInitialChannel:
        (this.channel = t.channel),
          this.setLocation(
            null !== (n = this.location) && void 0 !== n ? n : "Unknown"
          ),
          this.getAvailability();
        break;
      case ChatMethod.getAvailability:
        this.setAvailability(t.available);
    }
  }
  getAvailability() {
    this.channel
      ? this.send(ChatMethod.getAvailability, { id: this.channel.id })
      : console.warn(
          "ChatClient.getAvailability: Cannot get availability: Not yet connected"
        );
  }
  setAvailability(e) {
    this.available !== e &&
      ((this.available = e), this.onAvailabilityChange(e));
  }
  login(t, e) {
    return this.credentialServiceURL && e
      ? fetch(this.credentialServiceURL, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ hash: e, client: "ChatClient.login" }),
        })
          .then((e) => e.json())
          .then(
            (e) =>
              "0" === (null == e ? void 0 : e.error) &&
              null != e &&
              e.credentials
                ? new Credentials(t, e.credentials.session, e.credentials.hmac)
                : (console.debug(
                    "Credential error:",
                    null == e ? void 0 : e.error
                  ),
                  new Credentials(t)),
            (e) => (
              console.debug("Credential request error:", e), new Credentials(t)
            )
          )
      : Promise.resolve(new Credentials(t));
  }
  ping() {
    this.websocket
      ? this.websocket.send("ping")
      : console.warn("ChatClient.ping: Cannot ping: Not connected");
  }
  send(e, t, n) {
    this.websocket && this.websocket.readyState === WebSocket.OPEN
      ? this.websocket.send(
          JSON.stringify({ hmac: n, id: e, method: e, params: t })
        )
      : console.error(
          "ChatClient.send: Cannot send. WebSocket not in open state: ",
          null === (n = this.websocket) || void 0 === n ? void 0 : n.readyState
        );
  }
}
(ChatClient.INTERVAL_PING = 120), (ChatClient.INTERVAL_AVAIL = 90);


