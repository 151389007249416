import React, { useContext } from "react";
import casino from "../../config";

let docRef = 'mailto:' + casino.support_documents;
let cafRef = {
    en: "/CAF/en.pdf",
    de: "/CAF/de.pdf",
    fr: "/CAF/fr.pdf",
    es: "/CAF/es.pdf",
    it: "/CAF/it.pdf",
}

const banking = {
    "en": {
        sections: [{
            title: "Withdrawals",
            content: ["At Casino " + casino.name + ", we are always thrilled when our players get the chance to withdraw their winnings. We believe that it is in our best interest to make this process as streamlined as possible.", "The following documents are required by the casino in order to approve a pending withdrawal. This is a standard industry request and will only be necessary once. The documents are:",
            <ul>
                <li>A Copy of ID or passport (Front and Back).</li>
                <li>A recent Utility Bill showing player name and address as they appear in the account.</li>
                <li>A Copy of any Credit Card or Debit Card used to make a deposit (front and back). Please cover
                    all numbers
                </li>
                <li>except for the first four and last four.</li>
                <li>A signed CAF (Card Authorization Form) found here:<a href={cafRef.en} target="_blank" download> PDF</a>.
                </li>
            </ul>, "If you have used an Alternative payment method such as Neteller or Skrill, you will only need to provide:",
            <ul>
                <li>A Copy of ID or passport (Front and Back).</li>
                <li>A recent Utility Bill showing player name and address as they appear in the account.</li>
            </ul>, <>The best way to send in these documents is by taking digital pictures of the above and sending
                them to <a href={docRef}>{casino.support_documents}</a>. Please include
                your name and nickname and please be sure the pictures are not too blurry. Once the Finance
                department has reviewed and approved the verification documents, you name will be added to the next
                available payment cycle.</>]
        }, {
            title: "Fraud prevention",
            content: ["The above mentioned verification documents may also be requested for fraud prevention purposes. The casino reserves the right to request and require verification documents in order to validate any account deemed essential.", <>Please
                note, a player will not be able to play until the necessary documents have been received and
                approved by the casino. Verification Documents may also be sent via email to <a
                    href={docRef}>{casino.support_documents}</a>.</>, "Please note by completing a transaction you automatically agree to our Terms and Conditions and Privacy Policy.", "A 3% processing fee will be taken on all deposits."]
        }]
    }, "fr": {
        sections: [{
            title: "",
            content: ["Bienvenue dans notre section de paiements, section dans laquelle vous pourrez prendre connaissance des méthodes de paiements pour déposer ou retirer vos gains. Tous les transferts d'argent sont sécurisés grâce aux méthodes de cryptage SSL permettant de sécuriser vos informations personnelles et bancaires. " + casino.name + " Casino s'engage à garantir des transactions bancaires de manière sures et fiables aussi bien lors de vos dépôts que des retraits de vos gains."]
        }, {
            title: "Documents necessaires au retrait de vos gains",
            content: ["Les documents suivants sont requis par le casino afin d'approuver un retrait en attente. Il s'agit d'une demande de l'industrie standard et ne sera nécessaire qu'une seule fois.", "Les documents sont les suivants:",
                <ul>
                    <li>Une copie (recto et verso) de la carte d'identité ou passeport</li>
                    <li>Un Justificatif de domicile récent de moins de 3 mois indiquant le nom du joueur et adresse tels
                        qu'ils apparaissent dans le compte de casino
                    </li>
                    <li>Copie de toute carte de crédit utilisée pour effectuer un dépôt (recto et verso). Merci de
                        cacher les numéros du milieu en laissant apparaitre les 4 premiers et 4 derniers chiffres.
                    </li>
                    <li>Un CAF signé (formulaire d'autorisation de carte),<a href={cafRef.fr}
                        target="_blank" download> cliquez ici</a>.
                    </li>
                </ul>, <>Le moyen le plus populaire pour envoyer ces documents est en prenant simplement une photo
                    numérique de chaque document demandé, et les envoyer à <a href={docRef}>{casino.support_documents}</a> en tant que pièces
                    jointes. Veuillez s'il vous plaît inclure votre nom et votre nom d'utilisateur.</>]
        }, {
            title: "Documents pour la prévention de la fraude",
            content: [<>Un joueur ne sera pas en mesure de jouer jusqu'à ce que les documents nécessaires aient été
                reçus et approuvés par le casino. Les documents de vérification peuvent également être envoyés par
                courrier électronique à <a href={docRef}>{casino.support_documents}</a></>]
        }]
    }, "de": {
        sections: [{
            title: "Ein- und auszahlungen",
            content: ["Bei " + casino.name + " sind wir immer begeistert, wenn unsere Spieler die Möglichkeit haben, ihre Gewinne abzuheben. Wir glauben, dass es in unserem besten Interesse ist, diesen Prozess so rational wie möglich zu gestalten."]
        }, {
            title: "Für auszahlungen nötigen dokumente",
            content: ["Die nachfolgenden Dokumente müssen der Spielbank zur Bestätigung einer bevorstehenden Auszahlung vorgelegt werden. Es handelt sich um eine branchenübliche Standard-Prozedur, die nur einmal erforderlich ist. Bei den Dokumenten handelt es sich um:",
                <ul>
                    <li>Eine Kopie von Ihrem Führerschein, Personalausweis oder Reisepass (Vorder- und Rückseite).</li>
                    <li>Eine aktuelle Nebenkostenabrechnung (Strom oder Wasser), aus der Ihr Name und Ihre Adresse
                        entsprechend Ihrer Anmeldung im Spielbank ersichtlich sind.
                    </li>
                    <li>Eine Kopie der Kreditkarte (Vorder- und Rückseite), die Sie zur Einzahlung einsetzen. Bitte
                        decken Sie beim Kopieren alle Ziffern der Kreditkartennummer außer den ersten sechs und letzten
                        vier Ziffern ab.
                    </li>
                    <li>Eine unterschriebene Bestätigung der Auszahlungsanforderung (CAF, Card Authorization Form), die
                        Sie hier finden:<a href={cafRef.de} target="_blank" download> PDF</a>
                        .
                    </li>
                    <li>Am einfachsten ist es, vom Personalausweis oder Reisepass, von der Kreditkarte, der Strom- oder
                        Wasserabrechnung und dem CAF Digitalfotos zu machen und diese als E-Mail-Anhang an <a href={docRef}>{casino.support_documents}</a> zu senden. Bitte
                        vermerken Sie dabei Ihren richtigen Namen und Ihren Spielernamen.
                    </li>
                </ul>]
        }, {
            title: "Dokumente zum schutz vor missbrauch",
            content: ["Die Vorlage der oben genannten Dokumente kann auch zum Schutz vor eventuellem Missbrauch Ihres Kontos erforderlich werden.", "Bitte beachten Sie, dass die Spielbank sich das Recht vorbehält, jederzeit Dokumente zur Überprüfung der Richtigkeit der Angaben anzufordern, sofern dies für erforderlich gehalten wird.", <>Bevor
                die erforderlichen Dokumente eingereicht und von der Spielbank überprüft worden sind,
                ist eine
                Spielteilnahme nicht möglich. Dokumente zur Verifikation können auch per E-Mail an <a href={docRef}>{casino.support_documents}</a> geschickt
                werden.</>]
        }]
    }, "es": {
        sections: [{
            title: "",
            content: ["Bienvenido a la sección de Métodos de Pago donde se pueden ver todas las formas de como hacer un depósito y/o retirar dinero del casino . Todo depósito y recarga de dinero son seguros y llevan encriptación de datos SSL para asi asegurarte que tu información está 100% segura en todo momento. " + casino.name + " Casino está comprometido a proveerte transacciones bancarias seguras y fiables en todo momento garantizándote siempre prontitud en los pagos."]
        }, {
            title: "Documentos para retiros",
            content: ["Los siguientes documentos son requeridos por el casino con el fin de aprobar un retiro pendiente. Esta es una solicitud estándar de la industria, y sólo será necesaria enviarla una vez. Los documentos son:",
                <ul>
                    <li>Una copia del Documento Nacional de Identidad, licencia de conducir o pasaporte (caras delantera
                        y trasera).
                    </li>
                    <li>Un reciente pago de servicios públicos que muestre el nombre del jugador y la dirección
                        registrada en la cuenta del casino.
                    </li>
                    <li>Copia de cualquier tarjeta de crédito ya utilizada en el casino para hacer un depósito (anverso
                        y reverso). Por favor, cubrir los ocho números del centroo dejando a la vista los cuatro
                        primeros y los últimos cuatro números.
                    </li>
                    <li>Un formulario de autorización de la tarjeta (CAF) que se encuentran aquí: <a href={cafRef.es} target="_blank" download>PDF</a></li>
                    <li>La manera más popular para enviar estos documentos es simplemente tomando una fotografía digital
                        de su Documento de Identidad, Tarjeta de Crédito, facturas de servicios públicos y la
                        CAF, y enviarlo a <a href={docRef}>{casino.support_documents}</a> como un archivo
                        adjunto de correo electrónico.
                        Por favor, incluya su nombre y nombre de usuario.
                    </li>
                </ul>]
        }, {
            title: "Documentos para la prevención del fraude",
            content: ["Los documentos antes mencionados también se pueden solicitar con fines de prevención de fraude o reapertura de cuentas.", "Tenga en cuenta que el casino se reserva el derecho de solicitar y requerir documentos de verificación a fin de validar cualquier cuenta de casino.", "Un jugador al cual se le requeiran estos documentos no podrá jugar hasta que se hayan recibido y aprobado por el casino todos los documentos necesarios. Los documentos de verificación también se pueden enviar por correo electrónico a <a \" href='mailto:documents@true-fortune.com'>documents@true-fortune.com </a>"]
        }]
    }, "it": {
        sections: [{
            title: "",
            content: ["Benvenuto alla sezione finanziaria dove potrai scoprire tutti I modi per effettuare un deposito o prelevare denaro dal casinò. Tutte le opzioni di deposito e prelievo sono sicure e protette con crittografia dei dati SSL per garantire che le vostre informazioni siano al sicuro in ogni momento. " + casino.name + " Casino si impegna a fornire transazioni sicure e affidabili in ogni momento pagamenti rapidissimi."]
        }, {
            title: "Documenti per prelievi",
            content: ["Per poter approvare una richiesta di prelievo, verranno richiesti dei documenti. Si tratta di una prassi che accomuna tutti i casinò online," +
                " e che verrà effettuata una sola volta. I documenti in questione sono:",
            <ul>
                <li>Una copia di un Documento d’Identità o passaporto (Fronte e retro).</li>
                <li>Una bolletta recente che mostra il nome del giocatore e l'indirizzo come appaiono nel conto.
                </li>
                <li>La copia della carta di credito utilizzata per effettuare il deposito nel casino (fronte e
                    retro). Si prega di coprire tutti i numeri eccetto le prime 4 e le ultime 4 cifre.
                </li>
                <li>Il CAF firmato (Lettera di autorizzazione) che potrai trovare qui: <a
                    href={cafRef.it} target="_blank" download>PDF </a></li>
                <li> "La maniera più semplice di inviare i documenti è semplicemente scattare una foto digitale del
                    vostro Documento d’Identità, carta di credito, bolletta e CAF
                    , e inviarlo a <a href={docRef}>{casino.support_documents}</a> come
                    allegato per e - mail.Si prega di inserire nell’ e - mail nome e nickname.
                </li>
            </ul>,]
        }, {
            title: "Documenti per prevenzione delle frodi",
            content: ["I documenti descritti in alto potranno essere richiesti anche per prevenire casi di frodi.", "Nota bene, il casinò si riserva il diritto di richiedere documenti di verifica in qualsiasi momento al fine di convalidare qualsiasi conto di gioco", "Il giocatore non potrà giocare fino a che I documenti richiesti non siano stati ricevuti e approvati dal casinò stesso."]
        }]
    },
    "pt": {
        sections: [{
            title: "Levantamentos",
            content: ["No Casino " + casino.name + ", estamos sempre entusiasmados quando nossos jogadores têm a chance de sacar seus ganhos. Acreditamos que é do nosso melhor interesse tornar esse processo o mais eficiente possível.", "Os seguintes documentos são necessários pelo cassino para aprovar um saque pendente. Esta é uma solicitação padrão da indústria e será necessária apenas uma vez. Os documentos são:",
            <ul>
                <li>Uma cópia do RG ou passaporte (frente e verso).</li>
                <li>Uma conta recente de serviços públicos mostrando o nome e o endereço do jogador conforme constam na conta.</li>
                <li>Uma cópia de qualquer cartão de crédito ou débito usado para fazer um depósito (frente e verso). Por favor, cubra todos os números</li>
                <li>exceto os quatro primeiros e os quatro últimos.</li>
                <li>Um Formulário de Autorização de Cartão (CAF) assinado encontrado aqui: <a href={cafRef.pt} target="_blank" download>PDF</a>.</li>
            </ul>, "Se você usou um método de pagamento alternativo, como Neteller ou Skrill, você só precisará fornecer:",
            <ul>
                <li>Uma cópia do RG ou passaporte (frente e verso).</li>
                <li>Uma conta recente de serviços públicos mostrando o nome e o endereço do jogador conforme constam na conta.</li>
            </ul>, <>A melhor maneira de enviar esses documentos é tirar fotos digitais dos itens acima e enviá-los para <a href={docRef}>{casino.support_documents}</a>. Por favor, inclua
                seu nome e apelido e certifique-se de que as fotos não estejam muito desfocadas. Assim que o departamento financeiro revisar e aprovar os documentos de verificação, seu nome será incluído no próximo ciclo de pagamentos disponível.</>]
        }, {
            title: "Prevenção de Fraudes",
            content: ["Os documentos de verificação mencionados acima também podem ser solicitados para fins de prevenção de fraudes. O cassino reserva-se o direito de solicitar e exigir documentos de verificação para validar qualquer conta considerada essencial.", <>Por
                favor, observe que um jogador não poderá jogar até que os documentos necessários sejam recebidos e
                aprovados pelo cassino. Os Documentos de Verificação também podem ser enviados por e-mail para <a
                    href={docRef}>{casino.support_documents}</a>.</>, "Ao completar uma transação, você automaticamente concorda com nossos Termos e Condições e Política de Privacidade.", "Uma taxa de processamento de 3% será aplicada em todos os depósitos."]
        }]
    },
}
export default banking;