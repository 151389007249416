import React from "react";
import Bonus from "./Bonus";

export default function MainSeparator({data}) {

  return (
    <div className="mainboxes">
      
      <div className="box-one">{data["bonus"][0].place === 1 && <Bonus data={data['bonus']} />}</div>
      <div className="box-two">{data["bonus"][0].place === 2 && <Bonus data={data['bonus']} />}</div>
      <div className="box-three"> {data["bonus"][0].place === 3 && <Bonus data={data['bonus']} />}
        {/* <Form /> */}
      </div>
    </div>
  );
}
