
const footer_images = {
    EightTeen: {name: 'EightTeen', url: '/images/footer_images/18+.png'},
    Rival: {name: 'Rival', url: '/images/footer_images/rival.png'},
    Visa: {name: 'Visa', url: '/images/footer_images/visa.png'},
    VerifiedByVisa: {name: 'Visa', url: '/images/footer_images/verified_by_visa.png'},
    MasterCard: {name: 'MasterCard', url: '/images/footer_images/mastercard.png'},
    Neteller: {name: 'Neteller', url: '/images/footer_images/neteller.png'},
    PaySafeCard: {name: 'PaySafeCard', url: '/images/footer_images/paysafecard.png'},
    Ucash: {name: 'Ucash', url: '/images/footer_images/ukash.png'},
    Arrowedge: { name: 'Arrowedge', url: '/images/footer_images/arrow_edge.png' },
    Betsoft: { name: 'Betsoft', url: '/images/footer_images/betsoft.png' },
    Bitcoin: { name: 'Bitcoin', url: '/images/footer_images/bitcoin.png' },
    CashlibColor: { name: 'CashlibColor', url: '/images/footer_images/cashlib_color.png' },
    Cashlib: { name: 'Cashlib', url: '/images/footer_images/cashlib.png' },
    ConstantCrypto: { name: 'ConstantCrypto', url: '/images/footer_images/constant_crypto.png' },
    Curaco: { name: 'Curaco', url: '/images/footer_images/curacao_logo.png' },
    Ecopayz: { name: 'Ecopayz', url: '/images/footer_images/ecopayz.png' },
    Giroplay: { name: 'Giroplay', url: '/images/footer_images/giro_play.png' },
    Jeton: { name: 'Jeton', url: '/images/footer_images/jeton.png' },
    MasterCardSecure: { name: 'MasterCardSecure', url: '/images/footer_images/master_card_secure_code.png' },
    Neosurf: { name: 'Neosurf', url: '/images/footer_images/neosurf.png' },
    PanDeBono: { name: 'PanDeBono', url: '/images/footer_images/pan_de_bono.png' },
    Payecard: { name: 'Payecard', url: '/images/footer_images/payecard.png' },
    PostPay: { name: 'PostPay', url: '/images/footer_images/postpay.png' },
    Pyesafe: { name: 'Pyesafe', url: '/images/footer_images/pyesafe.png' },
    Saucify: { name: 'Saucify', url: '/images/footer_images/saucify.png' },
    Skrill: { name: 'Skrill', url: '/images/footer_images/skrill.png' },
    Noda: { name: 'Noda-Identity-Verification', url: '/images/footer_images/noda_identity_verification.png' },
    Interac: { name: 'Interac', url: '/images/footer_images/interac.png' }
}

export default footer_images