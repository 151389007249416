import React from "react";
import casino from "../config";
import { v4 as uuidv4 } from "uuid";
import casino_contents from "../contents";
import { langContext } from "../helper/langcontext";
import { useContext } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

export default function Faq() {
  const lang = useContext(langContext);
  const faq = casino_contents.faq[lang.language];
  const pages = casino.layout.footer.pages;
  const path = window.location.pathname;
  // console.log(faq);
  return (
    <>
        <Header/>
      <div className="middle_bg_pic"></div>
      <div className="middle_content pages">
        <div className="pages_options">
          {pages.map((x) => {
            return (
              <button
                key={uuidv4()}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = x.href;
                }}
                className={x.href === path ? "btn pg active" : "btn pg"}
              >
                {/*{console.log(x.href)}*/}
                {x[lang.language]}
              </button>
            );
          })}
        </div>
        <div className="pages_contents">
            <div className="pages_content_wrapper">
          <h1>{faq.title}</h1>
          <div>{faq.content.map((content,key)=><p key={key}>{content}</p>)}</div>

          {faq.sections.map((section) => (
            <>
              <h2>{section.title}</h2>
              {section.items.map((item, key) => (
                <div className={"collapse"} key={key}>
                  <h4
                    onClick={(event) =>
                      event.target.parentElement.classList.toggle("open")
                    }
                  >
                    {item.title}
                  </h4>
                  <div className={"p_content"}>
                    {item.content.map((content,key)=><p key={key}>{content}</p>)}
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
        </div>
      </div>
        <Footer/>
    </>
  );
}
