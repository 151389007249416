import React from "react";
import enPic from "../../en.svg";
import {useState} from "react";
import LanguageOptions from "../languageOptions/LanguageOptions";
import LiveChat from "../LiveChat/LiveChat";
import {v4 as uuidv4} from "uuid";
import {useContext} from "react";
import {langContext} from "../../helper/langcontext";
import casino from "../../config";
import {pages as language} from "../../languages/buttons";

export default function Footer() {
    const lang = useContext(langContext);
    const footer_images = casino.layout.footer.footer_images;
    const pages = casino.layout.footer;
    const footer_top = casino.layout.footer.footer_top;
    const footer_bottom = casino.layout.footer?.footer_bottom
    const [languageOptions, setLanguageOptions] = useState(false);
    const [LiveChatHandler, setLiveChatHandler] = useState(false);
    // console.log(window.location)

    /**
     * It takes a name and a list of pages and returns a collapsable component
     * @param name - The name of the collapsable component.
     * @param pages - an array of objects that contain the name of the page and the href of the page.
     * @returns A collapsable component is being returned.
     */
    let collapsableComponent = (name, pages) => {
        return (
            <div className={"collapse"}>
                <h4
                    onClick={(event) =>
                        event.target.parentElement.classList.toggle("open")
                    }
                >
                    {name}
                </h4>
                <div className={"p_content"}>
                    {pages.map((x) => {
                        return (
                            <button
                                key={uuidv4()}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = x.href;
                                }}
                                className="btn footerPages"
                            >
                                {x[lang.language]}
                            </button>
                        );
                    })}
                </div>
            </div>)
    }

    const basicPageShow = (name, pages, secName, secPage) => {
        // console.log(secName);
        return (
            <>
                <div className="footer-section-desktop">
                    <span>{name}</span>
                    {pages.map((x) => {
                        return (
                            <button
                                key={uuidv4()}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = x.href;
                                }}
                                className="btn footerPages"
                            >
                                {x[lang.language]}
                            </button>
                        );
                    })}
                </div>
                {secName !== undefined &&
                    <div className="footer-section-desktop">
                        <span>{secName}</span>
                        {secPage.map((x) => {
                            return (
                                <button
                                    key={uuidv4()}
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = x.href;
                                    }}
                                    className="btn footerPages"
                                >
                                    {x[lang.language]}
                                </button>
                            );
                        })}
                    </div>
                }
            </>
        )
    }
    const languagePictureHandler = () => {
        switch (lang.language) {
            case "en":
                return "/images/lang_images/en.svg";
                break;
            case "de":
                return "/images/lang_images/de.svg";
                break;
            case "es":
                return "/images/lang_images/es.svg";
                break;
            case "fr":
                return "/images/lang_images/fr.svg";
                break;
            case "it":
                return "/images/lang_images/it.svg";
                break;

            default:
                return "/images/lang_images/en.svg";
        }
    }


    return (
        <>
            {LiveChatHandler && (
                <LiveChat callback={(value) => setLiveChatHandler(value)}/>
            )}
            {languageOptions && (
                <LanguageOptions
                    callback={(value) => {
                        if (languageOptions) {
                            setLanguageOptions(value)
                        }
                    }}


                />
            )}
            <div className="footer-wrapper">
                <div className="footer-inner">
                    <div className="footer-section top">

                        <div
                            onClick={() => {
                                languageOptions ? setLanguageOptions(false)
                                    : setLanguageOptions(true)
                            }}
                            className="language"
                        >
                            <img className="ft-imgs" src={languagePictureHandler()}/>
                            <button className="btn lang">{language.language[lang.language]}</button>
                        </div>

                        <div
                            className="live_chat_btn"
                            onClick={() => {
                                LiveChatHandler
                                    ? setLiveChatHandler(false)
                                    : setLiveChatHandler(true);
                            }}
                        >
                            <span className="ft-imgs chat"/>
                            <button className="btn chat"> {footer_top[0].en} </button>
                        </div>
                    </div>
                    <div className="footer-section images">
                        {footer_images.map((x) => {
                            if (!x && !x.url) return (<span></span>)
                            return (
                                <div key={uuidv4()} className="footer-imgs">
                                    <img src={x.url}/>
                                </div>
                            );
                        })}
                    </div>
                    <div className="footer-section pages">
                        {collapsableComponent("General Info", pages.general_info)}
                        {collapsableComponent("Casino", pages.casino)}
                        {collapsableComponent("Terms & Conditions", pages.terms_and_conditions)}
                        {collapsableComponent("Security and privacy", pages.security_and_privacy)}
                        {collapsableComponent("Promotions", pages.promotions)}

                        {basicPageShow("General info", pages.general_info)}
                        {basicPageShow("Casino", pages.casino)}
                        <div>
                            {basicPageShow("Terms & Conditions", pages.terms_and_conditions, "Security and privacy", pages.security_and_privacy)}
                        </div>
                        {basicPageShow("Promotions", pages.promotions)}

                    </div>
                </div>
                {!!footer_bottom && footer_bottom.text }
            </div>
        </>
    );
}
