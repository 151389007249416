import React, {useState, useEffect, useContext} from 'react'
import InnerHTML from 'dangerously-set-html-content'
import {langContext} from '../helper/langcontext'
import casino from '../config'


export default function XmasRaw() {
    const [toRenderContent, setToRenderContent] = useState(false)
    const language = useContext(langContext)
    const currentLang = language.language ?? 'en'
    const flares = `<img class="flare1 flares" src="/assets/landing/flare.png">
    <img class="flare2 flares" src="/assets/landing/flare.png">
    <img class="flare3 flares" src="/assets/landing/flare.png">`
    const lang = {
        en: {
            page_1: `<h2>GET READY FOR EVEN MORE <br/> THIS CHRISTMAS WITH ${casino.name.toLowerCase().includes('casino') ? casino.name : casino.name + ' CASINO'}</h2> <h1>$100,000 IN PRIZES</h1>`,
            page_2: `
            <h2> WIN YOUR SHARE OF</h2>
            <h1>$100,000</h1>
            <h1>IN PRIZES</h1>
            <h2>STARTING DECEMBER 1ST TILL DECEMBER 25TH,</h2>
            <h2>EVERY DAY WE'RE GIVING AWAY PRIZES AT RANDOM</h2>
            `,
            page_3: `
            <h2>WIN YOUR SHARE OF</h2> 
            <h1>$100,000</h1>
            <h1>IN PRIZES</h1>
            <h2>INCLUDING FREE</h2>
            <h3> IPHONE, IPAD, IMAC,</h3>
            <h3>AND SO MUCH MORE...</h3>
            `,
            page_4: `
            <h2>TO BE A PART OF THE ACTION AND TO STAND A CHANCE OF WINNING,SIMPLY LOG IN EACH DAY FROM NOW TILL CHRISTMAS, VISIT THE CASHIER AND SEE IF</h2> <h1>YOU ARE A DAILY WINNER</h1>
            `,
            page_5: `
            <h3>BUT WAIT,</h3> <h3>THERE IS EVEN MORE...,</h3>
            <h2>EVERY DAY YOU LOG IN; YOU WILL WIN EXTRA TICKETS
            FOR THE BIG MERRY CHRISTMAS DRAW TO WIN</h2>
            <h1> $10,000</h1>
            <h1>IN CASH</h1>
            `,
            2: 'CLAIM DAILY BONUS',
            12: "Monday",
            13: "Tuesday",
            14: "Wednesday",
            15: "Thursday",
            16: "Friday",
            17: "Saturday",
            18: "Sunday",

            page_6: `<h1 class="advent_calendar_text">ADVENT CALENDAR</h1>`,
            page_6_display: `<p class="rotate_your_phone">PLEASE <br> ROTATE <br> YOUR <br>PHONE</p>`,
            calendar_button: `GO TO CALENDAR`,
        },
        fr: {
            page_1: `<h2>PRÉPAREZ-VOUS POUR ENCORE PLUS<br/> CE NOËL AVEC  ${casino.name}</h2> <h1>100.000 € DE PRIX</h1>`,
            page_2: `
            <h2> GAGNEZ VOTRE PART DE</h2>
            <h1>100.000 € EN PRIX</h1>
            <h2>DU 1ER DÉCEMBRE AU 25 DÉCEMBRE,</h2>
            <h2>CHAQUE JOUR, NOUS OFFRONS DES PRIX AU RANDOM</h2>
            `,
            page_3: `
            <h2>GAGNEZ VOTRE PART DE</h2> 
            <h1>Y COMPRIS GRATUIT</h1>
            <h3> IPHONE, IPAD, IMAC,</h3>
            <h3>ET BIEN PLUS...</h3>
            `,
            page_4: `
            <h2>POUR FAIRE PARTIE DU JEU ET AVOIR UNE CHANCE DE GAGNER, CONNECTEZ-VOUS SIMPLEMENT CHAQUE JOUR À PARTIR DE MAINTENANT ET JUSQU'À NOËL,CONSULTER VOTRE CAISSE ET VOYEZ SI VOUS ÊTES UN </h2> <h1>DES 
            GAGNANTS QUOTIDIEN!
            </h1>
            `,
            page_5: `
            <h3>MAIS ATTENDEZ,</h3> <h3>IL Y A ENCORE PLUS...,</h3>
            <h2>CHAQUE JOUR OU VOUS VOUS CONNECTEZ; VOUS GAGNEREZ DES TICKETS SUPPLÉMENTAIRES</h2>
            <h2>POUR LE GRAND TIRAGE AU SORT DE NOËL À GAGNER</h2>
            <h1> 10000€ EN ARGENT</h1>
            `,
            2: "RÉCLAMER UN BONUS QUOTIDIEN",
            12: "Lundi",
            13: "Mardi",
            14: "Mercredi",
            15: "Jeudi",
            16: "Vendredi",
            17: "Samedi",
            18: "Dimanche",

            page_6: `<h1 class="advent_calendar_text">CALENDRIER DE L'AVENT</h1>`,
            page_6_display: `<p class="rotate_your_phone">VEUILLEZ <br> FAIRE <br> TOURNER <br>VOTRE <br> TÉLÉPHONE </p>`,
            calendar_button: `ALLER AU CALENDRIER`,
        },
        it: {
            page_1: `<h2>PREPARATI PER ANCORA DI PIÙ <br/> QUESTO NATALE CON  ${casino.name}</h2> <h1>$100,000 IN PREMI</h1>`,
            page_2: `
            <h2>VINCI LA TUA QUOTA DI</h2>
            <h1> $100,000</h1>
            <h2>DAL 1 DICEMBRE FINO AL 25 DICEMBRE, </h2>
            <h2>OGNI GIORNO REGALIAMO PREMI A CASO</h2>
            `,
            page_3: `
            <h2>Altri premi gratis</h2> 
            <h3>PHONE, IPAD, IMAC E TANTO ALTRO…</h3>
            `,
            page_4: `
            <h2>PER PARTECIPARE ALL'AZIONE E AVERE UNA POSSIBILITÀ DI VINCERE, ACCEDI OGNI GIORNO DA ORA FINO A NATALE, VISITA LA CASSA E VEDI SE,</h2> <h1>SEI UN VINCITORE GIORNALIERO!</h1>
            `,
            page_5: `
            <h3>MA ASPETTA,</h3> <h3>C'È ANCORA DI PIÙ...</h3>
            <h2>OGNI GIORNO ACCEDI; VINCERAI BIGLIETTI EXTRA PER LA GRANDE ESTRAZIONE DI BUON NATALE PER VINCERE</h2>
            <h1> $10,000 IN CONTANTI</h1>
            `,
            2: 'RICHIEDI IL BONUS GIORNALIERO',
            12: "Lunedì",
            13: "Martedì",
            14: "mercoledì",
            15: "giovedì",
            16: "venerdì",
            17: "Sabato",
            18: "Domenica",

            page_6: `<h1 class="advent_calendar_text">CALENDARIO DELL'AVVENTO</h1>`,
            page_6_display: `<p class="rotate_your_phone">PER FAVORE, <br> GIRI IL  <br> TUO <br>TELEFONO</p>`,
            calendar_button: `VAI AL CALENDARIO`,
        },
        de: {
            page_1: `<h2>MACHEN SIE SICH BEREIT FÜR NOCH MEHR <br/> DIESES WEIHNACHTEN BEI  ${casino.name} MIT</h2> <h1>100.000€ IN PREISEN</h1>`,
            page_2: `
            <h2> GEWINNEN SIE IHREN ANTEIL AN</h2>
            <h1>100.000€ IN PREISEN</h1>
            <h2>VOM 1. DEZEMBER BIS ZUM 25. DEZEMBER</h2>
            <h2>VERLOSEN WIR JEDEN TAG ZUFÄLLIGE PREISE</h2>
            `,
            page_3: `
            <h2>GEWINNEN SIE IHREN ANTEIL AN</h2> 
            <h1>100.000€ IN PREISEN</h1>
            <h2>INKLUSIVE GRATIS</h2>
            <h3>IPHONES, IPADS, IMACS</h3>
            <h3>UND SO VIEL MEHR...</h3>
            `,
            page_4: `
            <h2>UM TEIL DER AKTION ZU WERDEN UND GEWINNCHANCEN ZU HABEN, MELDEN SIE SICH EINFACH JEDEN TAG VON JETZT BIS WEIHNACHTEN AN, BESUCHEN SIE DIE KASSE UND SEHEN SIE,</h2> <h1>OB SIE DER TAGESGEWINNER SIND!
            </h1>
            `,
            page_5: `
            <h3>ABER WARTEN SIE,</h3> <h3>ES GIBT NOCH MEHR...</h3>
            <h2>JEDEN TAG, AN DEM SIE SICH ANMELDEN, ERHALTEN SIE EIN EXTRA LOS
            FÜR DIE GROSSE MERRY CHRISTMAS VERLOSUNG UND GEWINNEN SIE 
            </h2>
            <h1> 10.000 IN BAR</h1>
            `,
            2: 'TÄGLICHEN BONUS ERHALTEN',
            12: "Montag",
            13: "Dienstag",
            14: "Mittwoch",
            15: "Donnerstag",
            16: "Freitag",
            17: "Samstag",
            18: "Sonntag",

            page_6: `<h1 class="advent_calendar_text">ADVENTSKALENDER</h1>`,
            page_6_display: `<p class="rotate_your_phone">BITTE <br> DREHEN <br> SIE IHR <br>HANDY</p>`,
            calendar_button: `ZUM KALENDER GEHEN`,
        },
        es: {

            page_1: `<h2>PREPÁRATE PARA AÚN MÁS<br/> ESTA NAVIDAD CON  ${casino.name}</h2> <h1>$100,000 EN PREMIOS</h1>`,
            page_2: `
            <h2> GANA PARTE DE</h2>
            <h1>$100,000 EN PREMIOS</h1>
            <h2>DESDE EL 1 DE DICIEMBRE HASTA EL 25 DE DICIEMBRE,</h2>
            <h2>TODOS LOS DÍAS SORTEAMOS PREMIOS EN RANDOM</h2>
            `,
            page_3: `
            <h2>GANA PARTE DE</h2> 
            <h1>$100,000 EN PREMIOS</h1>
            <h2>INCLUYE COSAS GRATIS</h2>
            <h3> IPHONE, IPAD, IMAC,</h3>
            <h3>Y MUCHO MÁS....</h3>
            `,
            page_4: `
            <h2>PARA SER PARTE DE LA ACCIÓN Y TENER UNA OPORTUNIDAD DE GANAR, SIMPLEMENTE INICIE SESIÓN CADA DÍA DESDE AHORA HASTA NAVIDAD, VISITE EL CAJERO Y VEA SI ES UN </h2> <h1>GANADOR DIARIO.</h1>
            `,
            page_5: `
            <h3>PERO ESPERA,</h3> <h3>HAY  AÚN MÁS...,</h3>
            <h2>CADA DÍA QUE USTED INICIE SESIÓN; GANARÁS EXTRA BOLETOS PARA GANAR EL GRAN SORTEO FELIZ NAVIDAD</h2>
            <h1>$10,000 EN EFECTIVO</h1>
            `,
            2: 'RECLAME BONO DIARIO',
            12: "lunes",
            13: "martes",
            14: "miércoles",
            15: "Jueves",
            16: "Viernes",
            17: "Sábado",
            18: "Domingo",

            page_6: `<h1 class="advent_calendar_text">CALENDARIO DE ADVIENTO</h1>`,
            page_6_display: `<p class="rotate_your_phone">POR FAVOR, <br> GIRAR <br> SU <br>TELÉFONO</p>`,
            calendar_button: `IR AL CALENDARIO`,
        },

    }


    useEffect(() => {
        setToRenderContent(xmasRaw)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const xmasRaw = `
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        
        <script type="text/javascript" src="/assets/landing/xmas2020/mobile-detect.min.js"></script>
        <script src="/assets/landing/xmas2020/sircletype.js"></script>
        <!-- Swiper JS -->
        <script type="module">
        import Swiper from 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.mjs'

        var swiper = new Swiper(".mySwiper", {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            pagination: {
            el: ".swiper-pagination",
            clickable: true,
            },
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            },
        });
        if (swiper.originalParams.loop && swiper.loopedSlides < swiper.originalParams.slidesPerView) {
            swiper.params.slidesPerView = swiper.loopedSlides;
            swiper.destroy(false, false);
            swiper.init();
        }
        document.getElementById('go-to-calendar').addEventListener('click', function () {
            swiper.slideToLoop(5, 500, false);
        });

        swiper.on('slideChange', function () {
            var currentSlideIndex = swiper.realIndex;

            if (currentSlideIndex === 5) {
                document.getElementById('go-to-calendar').style.visibility = 'hidden';
            } else {
                document.getElementById('go-to-calendar').style.visibility = 'visible';
            }
          });
        </script>
        
    </head>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');
        html, body {
            margin: 0;
            padding: 0;
        }
        @media (min-width:1024px) {
            *{
            font-size: 1vmax;
            }
        }
        :focus, a, a:active, a:hover, a:focus {
            outline: none;
        }
        /* width */
        ::-webkit-scrollbar {
            background-color: #ebebeb;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #013c25;
            background: #004f30;
        } 
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #013c25;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #013c25;
        }
        
        body {
            overflow: hidden;
            background-color: #ae1405;
            font-family: 'Platypi', sans-serif;
            color: #fff;
            
        }
        body.hidden-calendar-btn #go-to-calendar {
            display: none;
        }
        .logo {
            position: fixed;
            width: 15em;
            margin: -0.3em 1em 0;
        }
        .logo img {
            width: 100%;
            object-fit: scale-down;
        }
        .swiper {
            width: 100%;
            height: 100vh;
          }
    
          .swiper-slide {
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            gap: 2em;
            overflow: hidden;
          }
    
          .swiper {
            margin-left: auto;
            margin-right: auto;
          }

        .swiper-button-next, .swiper-button-prev{
            color: #FFFFFF !important;
        }
        .swiper-pagination-bullet-active{
            background: #FFFFFF !important;
        }
        .header_container{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            background-color: transparent;
            padding: 20px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 100; 
        }
        .header_button_container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0;
        }
        .header_logo{
            width: 6em;
            max-width: 6em;
        }
        .slide_content_text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
        }
        .slide_content_button{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            margin-bottom: 10em;
        }
        .advent_calendar_text{
            display: none;
        }
        .rotate_your_phone{
            display: block;
        }
        .page2_content, .page4_content, .page5_content {
            align-self: flex-start;
            gap: 0;
            /* width: 30%; */
            /* padding-left: 9em; */
            /* padding-bottom: 5em; */
        }
        #page3{
            align-self: center;
        }
        #page4{
            align-self: center;
        }
        .page5_content{
            align-self: center;
        }
        .page2_content h1{
            font-size: 2em;
            margin: 0;
            color: white;
            text-shadow: 0px 0px 10px red;
        }
        .page2_content h1:first-of-type{
            font-size: 3em;
            font-weight: bold;
        }
        .page2_content h2,
        .page2_content h3,
        .page5_content h3{
            margin: 0;
            font-size: .8em;
        }
        .page5_content h3:last-of-type{
            margin-bottom: 1em;
        }
        .page2_content h3:first-of-type{
            font-size: 2em;
            font-weight: bold;
        }
        .page4_content h1{
            font-size: 3em;
            margin: 0;
            color: white;
            text-shadow: 0px 0px 10px red;
        }
        .page4_content h2{
            margin: 0;
            font-size: .8em;
        }
        .page5_content h1{
            font-size: 3em;
            margin: 0;
            color: white;
            text-shadow: 0px 0px 10px red;
        }
        .page5_content h1:first-of-type{
            font-size: 4em;
            font-weight: bold;
        }
        .page5_content h2{
            margin: 0em;
            line-height: 1.7;
            font-size: .8em;
        }
        #page5 .slide_content_button {
            margin-bottom: 16em;
        }
        .page6_content{
            gap: 4em;
        }
        #page1{
            background-image: url(/assets/landing/bgv1.png);
            position: relative;
            justify-content: center !important;
        }
        #page2{
            background-image: url(/assets/landing/bgv2.png);
            justify-content: center !important;
        }
        #page3{
            background-image: url(/assets/landing/bgv3.png);
            justify-content: center !important;
        }
        #page4{
            background-image: url(/assets/landing/bgv4.png);
            justify-content: center !important;
        }
        #page5{
            background-image: url(/assets/landing/bgv5.png);
            justify-content: center !important;
        }
        #page6 {
            background-image: url(/assets/landing/bgv6.png);
            overflow-y: auto;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            justify-content: center !important;
        }
        #page1 h1{
            font-size: 2em;
            margin: 0;
            color: white;
            text-shadow: 0px 0px 10px red;
        }
        .flares{
            position: fixed;
            width: 7em;
            opacity: 0;
	        animation: flare 2s infinite;
        }
        #page1 .flare1{
            top: 4em;
            left: 2em;
	        animation-delay: 0.5s;
        }
        #page1 .flare2{
            top: 9em;
            left: 17em;
            animation-delay: 1s;
        }
        #page1 .flare3{
            top: 17em;
            left: 5.5em;
        }
        #page2 .flare1{
            top: 6em;
            left: 40em;
	        animation-delay: 0.5s;
        }
        #page2 .flare2{
            bottom: -5em;
            right: 0.5em;
            animation-delay: 1s;
        }
        #page2 .flare3{
            bottom: -5em;
            left: 1em;
        }
        #page3 .flare1{
            top: 7em;
            left: 68em;
            animation-delay: 0.5s;
        }
        #page3 .flare2{
            bottom: 16em;
            left: 35em;
            animation-delay: 1s;
        }
        #page3 .flare3{
            bottom: -2em;
            right: 16em;
        }
        #page4 .flare1{
            top: 10em;
            left: 76em;
            animation-delay: 0.5s;
        }
        #page4 .flare2{
            bottom: 21em;
            left: 40em;
            animation-delay: 1s;
        }
        #page4 .flare3{
            bottom: -6em;
            left: 6em;
        }
        #page5 .flare1{
            bottom: 1em;
            right: 5em;
            animation-delay: 0.5s;
        }
        #page5 .flare2{
            bottom: 6em;
            left: 49em;
            animation-delay: 1s;
        }
        #page5 .flare3{
            bottom: -2em;
            right: 22em;
        }
        #page6 .flare1{
            top: 12em;
            left: -2em;
            animation-delay: 0.5s;
        }
        #page6 .flare2{
            bottom: 12em;
            right: -1em;
        }
        #page6 h1{
            color: white;
            font-size: 1.5em;
            top: 1em;
            position: fixed;
        }
        #page1 h2{
            font-size: .8em;
            text-transform: uppercase;
            margin: 0;
        }

        .xmas-btn {
            font-family: 'Platypi', sans-serif;
            background: url(/assets/landing/button_red_1.png) transparent no-repeat center ;
            border: none;
            width: min(60em, 60vw);
            height: 15em;
            background-size: contain;
            cursor: pointer;
        }
        .notcurved {
            color: #990000;
            font-weight: bold;
            display: block;
            margin-top: 0.2em;
        }
        
        .go_to_calendar_button{
            font-family: 'Platypi', sans-serif;
            padding: .5em 4em;
            border-radius: 9999999999px;
            border: 2px solid white;
            outline: none;
            color: white;
            background: #209d00;
            font-weight: bold;
            font-size: .6em;
            text-transform: uppercase;
            box-shadow: 0px 0px 8px white;
            cursor: pointer;
        }
        .go_to_calendar_button:hover{
            background: #d30b2e;
        }
        .claim_daily_bonus_button{
            padding: 0.3em 3em;
            border-radius: 9999999999px;
            border: none;
            outline: none;
            color: #d30d2e;
            background: linear-gradient(0deg, #fffd0d 0%, #ffdbd9 100%);
            box-shadow: rgb(255, 253, 13) 0px 0px 20px 2px;
            font-weight: bold;
            font-size: 1em;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;
            position: relative;
        }
        .claim_daily_bonus_button:hover{
            color: white;
            background: #d30b2e;
        }
        #sound {
            background: url(/assets/landing/sound.png) no-repeat center;
            border: none;
            width: 3.6em;
            height: 3.5em;
            background-size: contain;
            padding: 0;
            cursor: pointer;
            margin-left: 1em;
        }
        #logo-link {
            position: fixed;
            top: 1vh;
            z-index: 100;
            left: 0.5vw;
        }
        #calendar-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #calendar {
            width: 100%;
            border-spacing: 0;
            border-collapse: separate;
        }
        #calendar-body{
            display: flex;
            gap: 1em;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        tr {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 1em;
        }
        th {
            font-family: 'Platypi', sans-serif;
            text-transform: uppercase;
            font-size: 2.5em;
        }
        td, th {
            width: calc(100% / 10);
            text-align: center;
        }
        td {
            background: url(/assets/landing/calendar/empty.png) no-repeat center;
            background-size: contain;
            padding: 0;
            vertical-align: middle;
            position: relative;
            height: 5em;
            width: 8em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        td.empty {
            background-image: url(/assets/landing/calendar/empty.png);
        }
        td.past, td.today {
            background-image: url(/assets/landing/calendar/open.png);
        }
        tr:not(:first-child) td img {
        }
        td img {
            object-fit: scale-down; 
            height: 75%;
            width: 100%;
            margin: 0.5em 1em 1em -0.5em;
        }
        td.past img {
            height: 11em;
            object-fit: scale-down;
            margin-top: 0em;
        }
        .winner {
            position: absolute;
            width: 100%;
        }
        .winner span {
            font-family: 'Montserrat', sans-serif;
            display: inline-block;
            width: 100%;
            text-align: center;
            white-space: nowrap;
        }
        .winner span:last-child{
            font-size: .6em;
            color: #ffff00;
            font-weight: bold;
        }
        .day-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        .day-content > img {
            display: none;
        }
        .day-content > img:first-child {
            display: block;
        }
        .dd {
           font-family: 'Platypi', sans-serif;
           position: absolute;
           top: 0.1em;
           font-size: 1.2em;
           right: 0.5em;
           font-weight: 600;
           color: #ffff00;
        }
        .future .dd, .future .winner {
            display: none;
        }
        @keyframes flare {
            0% {
              opacity: 0.1;
            }
            5% {
              opacity: 0.2;
            }
            10% {
              opacity: 0.3;
            }
            15% {
              opacity: 0.4;
            }
            25% {
              opacity: 0.5;
            }
            30% {
              opacity: 0.6;
            }
            35% {
              opacity: 0.7;
            }
            40% {
              opacity: 0.8;
            }
            45% {
              opacity: 0.9;
            }
            50% {
              opacity: 1;
            }
            60% {
              opacity: 0.9;
            }
            70% {
              opacity: 0.8;
            }
            80% {
              opacity: 0.5;
            }
            90% {
              opacity: 0.3;
            }
            100% {
              opacity: 0.1;
            }
          }
          .page6_content {
            display: none;
            }
            .rotate_your_phone{
                font-size: 2em;
                font-weight: bold;
            }

        /* Mobile horizontal */
        @media only screen and (orientation: landscape) and (max-width: 1024px){
            #page1{
                background-image: url(/assets/landing/bgh1.png);
                justify-content: flex-end !important;
            }
            #page2{
                background-image: url(/assets/landing/bgh2.png);
                justify-content: flex-end !important;
            }
            #page3{
                background-image: url(/assets/landing/bgh3.png);
                justify-content: flex-end !important;
            }
            #page4{
                background-image: url(/assets/landing/bgh4.png);
                justify-content: flex-end !important;
            }
            #page5{
                background-image: url(/assets/landing/bgh5.png);
                justify-content: flex-end !important;
            }
            #page6{
                background-image: url(/assets/landing/bgh6.png);
                justify-content: flex-end !important;
                overflow: scroll;
            }
            .header_logo{
                width: 6em;
                max-width: 6em;
            }
            #page5 .slide_content_button {
                margin-bottom: 2em;
            }
            .go_to_calendar_button{
                font-size: .6em;
            }
            .header_container{
                justify-content: space-between;
            }
            .header_button_container {
                gap: 1em;
            }
            .claim_daily_bonus_button {
                font-size: .8em;
            }
            .slide_content_button {
                margin-bottom: 2em;
            }
            .page2_content, .page4_content, .page5_content {
                align-self: flex-start;
                gap: 0;
                width: 35%;
                padding-left: 5em;
                padding-bottom: 1em;
            }
            .page2_content h2, .page2_content h3, .page5_content h3 {
                font-size: .8em;
            }
            .page2_content h3:first-of-type {
                font-size: 1em;
            }
            #page1 h2 {
                font-size: .6em;
            }
            #page1 h1 {
                font-size: 1.6em;
            }
            .page2_content h1{
                font-size: 2em;
            }
            .page2_content h1:first-of-type{
                font-size: 3em;
            }
            .swiper-slide {
                gap: 1em;
            }
            .slide_content_text {
                gap: .2em;
            }
            .flares {
                width: 8em; 
            }
            #page1 .flare1 {
                top: 1.5em;
                left: 27em;
            }
            #page1 .flare2 {
                top: -2em;
                left: 15.5em;
            }
            #page1 .flare3 {
                top: 8.5em;
                left: 17.8em;
            }
            .advent_calendar_text{
                display: block;
            }
            .page6_content {
                display: flex;
                margin-bottom: -6em;
                gap: 1em;
            }
            .rotate_your_phone{
                display: none;
            }
            .page4_content h1 {
                font-size: 2em;
            }
            .page5_content h1:first-of-type {
                font-size: 3em;
            }
            .page5_content h1 {
                font-size: 2em;
            }
            .page5_content h2 {
                font-size: .7em;
            }
            #calendar-body {
                gap: .5em;
            }
            tr {
                gap: .5em;
            }
            td {
                height: 3em;
                width: 5em;
            }
            .winner span {
                font-size: .7em;
            }
            .dd {
                font-size: .8em;
            }
            .winner span:last-child {
                font-size: .4em;
            }
        } 
        /* Desktop */
        @media(min-width: 1024px){
            .header_button_container {
                gap: 4em;
            }
            .header_logo{
                width: 12em;
                max-width: 12em;
            }
            #page1{
                background-image: url(/assets/landing/bg_page1.png);
                justify-content: flex-end !important;
            }
            #page2{
                background-image: url(/assets/landing/bgd2.png);
                justify-content: flex-end !important;
            }
            #page3{
                background-image: url(/assets/landing/bgd3.png);
                justify-content: flex-end !important;
            }
            #page4{
                background-image: url(/assets/landing/bgd4.png);
                justify-content: flex-end !important;
            }
            #page5{
                background-image: url(/assets/landing/bgd5.png);
                justify-content: flex-end !important;
            }
            #page6{
                background-image: url(/assets/landing/bgd6.png);
                overflow-y: auto;
                align-items: flex-start;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end !important;
            }
            .claim_daily_bonus_button{
                font-size: 1.8em;
            }
            .go_to_calendar_button{
                font-size: 1em;
            }
            .de-lang .go_to_calendar_button{
                font-size: 2em;
            }
            .header_container{
                justify-content: space-between;
            }
            .page6_content {
                display: flex;
            }
            .advent_calendar_text{
                display: block;
            }
            #page1 h2 {
                font-size: 1.3em;
            }
            #page1 h1 {
                font-size: 3em;
            }
            .slide_content_button {
                margin-bottom: 5em;
            }
            .flares{
                width: 16em;
            }
            #page1 .flare1{
                top: -4em;
                left: 33em;
            }
            #page1 .flare2{
                top: 5em;
                left: 59.5em;
            }
            #page1 .flare3{
                top: 21em;
                left: 38em;
            }
            #page2 .flare1{
                top: 6em;
                left: 40em;
            }
            #page2 .flare2{
                bottom: -5em;
                right: 0.5em;
            }
            #page2 .flare3{
                bottom: -5em;
                left: 1em;
            }
            #page3 .flare1{
                top: 7em;
                left: 68em;
            }
            #page3 .flare2{
                bottom: 16em;
                left: 35em;
            }
            #page3 .flare3{
                bottom: -2em;
                right: 16em;
            }
            #page4 .flare1{
                top: 10em;
                left: 76em;
            }
            #page4 .flare2{
                bottom: 21em;
                left: 40em;
            }
            #page4 .flare3{
                bottom: -6em;
                left: 6em;
            }
            #page5 .flare1{
                bottom: 1em;
                right: 5em;
            }
            #page5 .flare2{
                bottom: 6em;
                left: 49em;
            }
            #page5 .flare3{
                bottom: -2em;
                right: 22em;
            }
            #page6 .flare1{
                top: 12em;
                left: -2em;
            }
            #page6 .flare2{
                bottom: 12em;
                right: -1em;
            }
            .page2_content h2, .page2_content h3, .page5_content h3 {
                font-size: 1.5em;
            }
            .page2_content h1{
                font-size: 4em;
            }
            .page2_content h1:first-of-type{
                font-size: 6em;
            }
            .page2_content, .page4_content, .page5_content {
                align-self: flex-start;
                gap: 0;
                width: 30%;
                padding-left: 9em;
                padding-bottom: 8em;
            }
            #page3, #page4, #page5{
                align-self: flex-start;
            }
            .page4_content h2 {
                font-size: 1.5em;
            }
            .page4_content h1 {
                font-size: 4em;
            }
            .page5_content h2 {
                font-size: 1.1em;
            }
            .page5_content h1:first-of-type {
                font-size: 7em;
            }
            .page5_content h1 {
                font-size: 4.5em;
            }
            #page5 .slide_content_button {
                margin-bottom: 5em;
            }
            .rotate_your_phone{
                display: none;
            }
        }
        </style>
    <body class="en">
        <header class="header_container">
            <a href="/"><img class="header_logo" src="/brand/images/logo.png" title="Homepage"/></a>
            <div class="header_button_container">
                <button class="go_to_calendar_button" id="go-to-calendar">${lang[currentLang]["calendar_button"]}</button>
                <button id="sound"></button>
            </div>
        </header>
        <!-- Slider main container -->
        <div class="swiper mySwiper">
          <!-- Additional required wrapper -->
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div class="swiper-slide" id="page1">
                ${flares}
                <div class="slide_content_text">
                    ${lang[currentLang]["page_1"]}
                </div>
                <div class="slide_content_button">
                    <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                </div>
            </div><!-- page1 end -->
            <div class="swiper-slide" id="page2">
                ${flares}
                <div class="slide_content_text page2_content">
                    ${lang[currentLang]["page_2"]}
                </div>
                <div class="slide_content_button">
                    <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                </div>
            </div><!-- page2 end -->
            <div class="swiper-slide" id="page3">
                ${flares}
                <div class="slide_content_text page2_content">
                    ${lang[currentLang]["page_3"]}
                </div>
                <div class="slide_content_button">
                    <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                </div>
            </div><!-- page3 end -->
            <div class="swiper-slide" id="page4">
                ${flares}
                <div class="slide_content_text page4_content">
                    ${lang[currentLang]["page_4"]}
                </div>
                <div class="slide_content_button">
                    <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                </div>
            </div><!-- page4 end -->
            <div class="swiper-slide" id="page5">
                ${flares}
                <div class="slide_content_text page5_content">
                    ${lang[currentLang]["page_5"]}
                </div>
                <div class="slide_content_button">
                    <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                </div>
            </div><!-- page5 end -->
            <div class="swiper-slide" id="page6">
                ${lang[currentLang]["page_6"]}
                ${lang[currentLang]["page_6_display"]}
                <img class="flare1 flares" src="/assets/landing/flare.png">
                <img class="flare2 flares" src="/assets/landing/flare.png">
                <div class="slide_content_text page6_content">
                    <div id="calendar-wrapper">
                        <table id="calendar">
                            <tbody id="calendar-body"></tbody>
                        </table>
                        <script>
                        function generate_calendar() {
                            var year = new Date().getFullYear()
                            //year+"-12-02"
                            //var now = new Date("2021-12-01");
                            var now = new Date("2022-12-01");
                            var firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                            let row = 0
    
                            for (let i = 0; i < 25 + firstDay.getDay(); i++) {
                                if (i % 7 == 0 && i != 0) {
                                    $(".week-" + row).wrapAll("<tr class='row'></tr>");
                                    row++;
                                }
                                if (i < firstDay.getDay() - 1 || i > 27) {
                                    $("#calendar-body").append("<td class='empty week-" + row + "'></td>")
                                } 
                                else {
                                    $("#calendar-body").append("<td class='day week-" + row + "' id='day" + ((i + 1) - firstDay.getDay() + 1) + "'><div class='day-content'></div><div class='day-winner'></div></td>")
                                }
                                if(i == 25 + firstDay.getDay() - 1){
                                    $(".week-" + row).wrapAll("<tr class='row'></tr>");
                                }
                            }//end for
                        }//end function
                        generate_calendar();
                    </script>
                    <script>
                        var today = new Date();
                        var dd = today.getUTCDate();
                        var tomorrow = dd + 1;
                        //countdown
                        function getTimeRemaining(endtime) {
                            var today = new Date();
                            var t = Date.parse(endtime) - Date.parse(today);
                            var seconds = Math.floor((t / 1000) % 60);
                            var minutes = Math.floor((t / 1000 / 60) % 60);
                            var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
                            return {
                                'total': t,
                                'hours': hours,
                                'minutes': minutes,
                                'seconds': seconds
                            };
                        }
    
                        function initializeClock(id, endtime) {
                            var clock = document.getElementById(id);
                            var hoursSpan = clock.querySelector('.hours');
                            var minutesSpan = clock.querySelector('.minutes');
                            var secondsSpan = clock.querySelector('.seconds');
    
                            function updateClock() {
                                var t = getTimeRemaining(endtime);
    
                                hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                                minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                                secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
    
                                if(t.total<=0) {
                                    clearInterval(timeinterval);
                                }
                            }
                            updateClock();
                            var timeinterval = setInterval(updateClock, 1000);
                        }
    
                        function readJson() {
                            $.getJSON('/assets/landing/xmas2020/winners.json', function (data) {
                                //console.log(data)
                                var i = 1, day, img, dd, td;
                                var qData = today.getUTCDate();
                                qData = qData > 25 ? 25 : qData;
                                for (var m = 0; m < qData ; m++) {
                                    dd = m < 9 ? (m+1) : m+1;
                                    td = document.getElementById("day" + i);
                                    day = td.children[1];
                                    img = td.children[0];
                                    for (var k = 0; k < 5; k++) {
                                        var player = data.days[m].players[k];
                                        if (player) {
                                            $(day).addClass('winner').append('<div><span>' + player.name + '</span><br><span>' + ' won ' + player.prizetitle + '</span></div>');
                                            if (k > 0) {
                                                $(day).add(img).addClass('rotate');
                                            }
                                        }
                                    }
                                    $(td).append('<span class="dd">'+dd+'</span>');
                                    i++;
                                }
                                $("#today").appendTo($("#day" + i + ">.day-content"));
                                for (i = 1; i <= 25; i++) {
                                    var k = "day" + i;
                                    var dayBlock = $('#' + k + ' .day-content');
    
                                    if (dd < i || today.getUTCMonth() == 12) {
                                        dayBlock.parent().addClass('future');
                                    } else if (dd > i) {
                                        dayBlock.parent().addClass('past');
                                        dayBlock.addClass('past');
                                    } else {
                                        dayBlock.parent().addClass('today active');
                                        $($('th')[dayBlock.parent().index()]).addClass('active');
                                        if (dayBlock.parent().index()) {
                                          $($('th')[dayBlock.parent().index()-1]).addClass('left');
                                        } else {
                                            $($('th')[6]).addClass('left');
                                        }
                                        if (dayBlock.parent().prev('td').length) {
                                            dayBlock.parent().prev('td').addClass('left');
                                        } else { 
                                            $('td').last().addClass('left');
                                        }
                                        dayBlock.addClass('today');
                                    }
                                }
                                if (!$('td.active').length) {
                                  $('td').first().addClass('active');
                                  $('th').first().addClass('active');
                                  $('th').last().addClass('left')
                                }
                            });
                        }//end async function
                        readJson();
    
                        (function($){
                            $.fn.extend({
                                rotaterator: function(options) {
                                    var defaults = {
                                        fadeSpeed: 500,
                                        pauseSpeed: 100,
                                        child:null
                                    };
    
                                    var options = $.extend(defaults, options);
    
                                    return this.each(function() {
                                        var o =options;
                                        var obj = $(this);
                                        var items = $(obj.children(), obj);
                                        var next;
                                        items.each(function() {$(this).hide();});
    
                                        if(!o.child){
                                            next = $(obj).children(':first');
                                        }else{
                                            next = o.child;
                                        }
    
                                        $(next).fadeIn(o.fadeSpeed, function() {
                                            $(next).delay(o.pauseSpeed).fadeOut(o.fadeSpeed, function() {
                                                var next = $(this).next();
    
                                                if (next.length == 0){
                                                    next = $(obj).children(':first');
                                                }
                                                $(obj).rotaterator({child : next, fadeSpeed : o.fadeSpeed, pauseSpeed : o.pauseSpeed});
                                            })
                                        });
                                    });
                                }
                            });
                        })(jQuery);
    
                        setTimeout(function () {
                            $('td:not(.future) > .rotate').rotaterator({fadeSpeed:1000, pauseSpeed:1500});
                        }, 1000);
                    </script>
                    </div> <!-- calendar wrapper end -->
                    <div class="slide_content_button">
                        <a class="claim_daily_bonus_button" href="/instantplay">${lang[currentLang][2]}</a>
                    </div>
                </div>
            </div><!-- page6 end -->
          </div>
          <!-- If we need pagination -->
          <div class="swiper-pagination"></div>
    
          <!-- If we need navigation buttons -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
    
          <!-- If we need scrollbar -->
          <div class="swiper-scrollbar"></div>
        </div>
    
        <audio loop id="background_audio"><source src="/assets/landing/xmas2020/sound.mp3" type="audio/mpeg"></audio>
    
        <script type="text/javascript">
        
            var calendar = $('#calendar-wrapper');
            var logo = $('#logo-link');
            var body = $('body');
            var soundBtn = $('#sound').addClass(localStorage.getItem('soundLevel') || 'sound-all');
            var audio = document.getElementById('background_audio');
        
            $(document).one('click', function (e) {
                if ($(e.target).attr('id') !== 'sound') {
                    if (localStorage.getItem('soundLevel') !== 'sound-no') {
                        audio.play();
                        soundBtn.attr('class', '').addClass('sound-all');
                        localStorage.setItem('soundLevel', 'sound-all');
                    }
                }
            });
        
        
            soundBtn.on('click', setVolume);
        
            function setVolume () {
                var soundLevel = soundBtn.attr('class');
                var muted = false;
                soundBtn.attr('class', '');
        
                switch (soundLevel) {
                    case('sound-all'):
                        soundLevel = 'sound-no';
                        muted = true
                        break;
                    default:
                       audio.play();
                        soundLevel = 'sound-all';
                        break;
                }
        
                audio.muted = muted;
                soundBtn.addClass(soundLevel);
                localStorage.setItem('soundLevel', soundLevel);
            }
        
        </script>
    </body>
    </html>
`
    return (
        <InnerHTML html={toRenderContent && toRenderContent}/>
    )
}
