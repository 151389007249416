import React from 'react'

export default function AlwaysOne() {
    const bgColor = window.getComputedStyle(document.body, null).getPropertyValue('background-color');

    return (
    <div style={{color:bgColor, filter:'invert(1)'}}>
1
    </div>
  )
}
