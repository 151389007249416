import React from "react";
import { langContext } from "../helper/langcontext";
import { useContext } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";



export default function Not_found() {
    const lang = useContext(langContext)

    return (
        <>
            <Header/>
            <div className="middle_content pages">
            <h1 style={{color:"white", marginBottom:'250px',marginTop:"250px"}}>Page not found</h1>
            </div>
            <Footer/>
        </>
    );
}
