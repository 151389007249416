import React,{useContext, useEffect} from "react";
import all_games from "./search_bar_icons/all_games.png";
import jackpot from "./search_bar_icons/jackpot.png";
import live_casino from "./search_bar_icons/live_casino.png";
import new_icon from "./search_bar_icons/new.png";
import popular from "./search_bar_icons/popular.png";
import slots from "./search_bar_icons/slots.png";
import table from "./search_bar_icons/table.png";
import top from "./search_bar_icons/top.png";
import {useState} from "react";
import {pages} from "../../languages/buttons";
import {langContext} from "../../helper/langcontext";

export default function Search_bar() {
    const lang = useContext(langContext);

    useEffect(() => {
       setState(pages.all_providers[lang.language])
    }, [lang]);


    const [open, setOpen] = useState(false)
    const [state, setState] = useState()
    const providers = [
               {name: "Rival", value: "Rival"},
               {name: "Betsoft", value: "Betsoft"},
               {name: "Saucify", value: "Saucify"},
               {name: "Arrow's Edge", value: "Arrow_s_edge"},
               {name: "Dragon", value: "Dragon"},
               {name: "FreshDeck (Vivo)", value: "Fresh_Deck"},
               {name: "Qora", value: "Qora"}
           ];

    return (
        <div className="search_outer">
            <div className="search_bar_wrapper">
                <div className="search_bar">
                    <div className="search_inputs">

                        {/*<div className="search_button"> Search</div>*/}
                        <div className={`search_button${open ? ` open` : ' closed'}`} onClick={() => {
                            setOpen(x => !x)
                        }}><p>{state}</p>
                            <div className={`search_inner${open ? ` open` : ''}`}>
                                <div className="search_providers">
                                    {providers.map((x ) => {
                                        return <span onClick={(e) => {
                                            e.preventDefault();
                                            setState(x.name)
                                            window.location.href = "/games/" + x.value.toLowerCase();
                                        }}>{x.name}</span>
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="search_icons">
                        <div className="search_icon" onClick={(e) => {
                            e.preventDefault();

                            window.location.href = "/games/all_games";
                        }}>

                            <img src={all_games}/>
                            <span>{pages.all_games[lang.language]}</span>
                        </div>

                        <div className="search_icon"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/games/live_casino";
                             }}>
                            <img src={live_casino}/>
                            <span>{pages.live_casino[lang.language]}</span>
                        </div>
                        <div className="search_icon" onClick={(e) => {
                            e.preventDefault();

                            window.location.href = "/games/new_games";
                        }}>
                            <img src={new_icon}/>
                            <span>{pages.new_games[lang.language]}</span>
                        </div>

                        <div className="search_icon"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/games/popular";
                             }}>
                            <img src={popular}/>
                            <span>{pages.popular[lang.language]}</span>
                        </div>

                        <div className="search_icon"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/games/slots";
                             }}>
                            <img src={slots}/>
                            <span>{pages.slots[lang.language]}</span>
                        </div>

                        <div className="search_icon"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/games/table";
                             }}>
                            <img src={table}/>
                            <span>{pages.table[lang.language]}</span>
                        </div>

                        <div className="search_icon"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/games/top";
                             }}>
                            <img src={top}/>
                            <span>{pages.top[lang.language]}</span>
                        </div>
                        <div className="search_icon leaderboard"
                             onClick={(e) => {
                                 e.preventDefault();

                                 window.location.href = "/leader_board";
                             }}>
                            <img src={jackpot}/>
                            <span>{pages.leaderboard[lang.language]}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
