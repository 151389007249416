import {useState, useEffect, useContext} from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import casino from "../config";
import {langContext} from "../helper/langcontext";
import Arrow from "../constant_datas/right-arrow.png"

export default function Leader_board() {
    const lang = useContext(langContext);
    const incorrect = {
        fr:[{" ": "", 'Code incorrect, merci de réessayer': ""}],
        en:[{" ": "", "Incorrect code, Please try again": ""}],
        it:[{" ": "", 'Codice errato, si prega di riprovare': ""}],
        es:[{" ": "", 'Codigo Incorrecto, por favor intenta de nuevo': ""}],
        de:[{" ": "", 'Falscher Code, versuchen sie es nochmal': ""}],
        pt:[{" ": "", 'Código incorreto, por favor tente novamente': ""}],
    }
    const access = {
        fr:[{" ": "", 'Entrez votre code d\'accès': ""}],
        en:[{" ": "", 'Enter your access code': ""}],
        it:[{" ": "", 'Inserisci il tuo codice di accesso': ""}],
        es:[{" ": "", 'Ingrese su código de acceso': ""}],
        de:[{" ": "", 'Geben Sie Ihren Zugangscode ein': ""}],
        pt:[{" ": "", 'Insira seu código de acesso': ""}],
    }
    const entrants = {
        fr:'Participants seulement',
        en:'Entrants Only',
        it:'Solo partecipanti',
        es:'Solo participantes',
        de:'Nur Teilnehmer',
        pt:'Apenas participantes',
    }
    const needCode = {
        title: entrants[lang.language],
        updated: "",
        data: access[lang.language]
    }
    const wrongCode = {
        title: entrants[lang.language],
        updated: "",
        data: incorrect[lang.language]
    }

    const pleaseWait = {title: "Loading...", updated: "", data: [{" ": "", "": "Please wait..."}]}

    /* Setting the error state to null. */
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [code, setCode] = useState(undefined);
    const [codeForSubmit, setCodeForSubmit] = useState(undefined)
    const [items, setItems] = useState(needCode);

    const getBoard = () => fetch(casino.leaderboard + "&code=" + code)
        .then(res => res.text())
        .then(
            (result) => {
                const csv2array = x => x && x.split(/,(?=(?:[^\"]*\"[^\"]*\")*(?![^\"]*\"))/)

                const splitter = result.split("\r\n")
                const results = {
                    title: csv2array(splitter.shift())[1],
                    updated: csv2array(splitter.shift())[1],
                    code: csv2array(splitter.shift())[1],
                    headers: csv2array(splitter.shift())
                }
                results.data = splitter
                    .map(csv2array)
                    .map(row => (results.headers.reduce((pv, cv, i) => (pv[cv] = row[i], pv), {})))

                if (results.code.toUpperCase() === code.toUpperCase()) {
                    setItems(results);
                    setIsLoaded(true);
                } else {
                    //wrong coode
                    setCode(undefined)
                    setItems(wrongCode)
                    //console.log("wrong")
                }

            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )

    /**
     * It creates a form with a text input and a submit button
     * @returns A table with a search bar and a button.
     */
    const showModel = () => {

        return <>
            <tr>
                <th></th>
                <td><input type="password" className={"search_button"} style={{margin: "2em auto"}} onChange={(e) => {
                    setCodeForSubmit(e.target.value)
                }}/></td>
            </tr>
            <tr>
                <th></th>
                <td style={{display: "flex", justifyContent: "center"}}>

                    <button onClick={() => {
                        setCode(codeForSubmit);
                        setItems(pleaseWait);
                        return false
                    }}
                            className="btn st header"
                            style={{flexGrow: 9, justifyContent: "center", margin:"0"}}
                    >
                        <img className="lb-img" src={Arrow}></img>
                    </button>
                </td>
            </tr>
        </>
    }

    /* A React Hook. It is a function that is called every time the component is rendered. */
    useEffect(() => {
        if (code) getBoard(); else showModel()
    }, [code])

    return (
        <>
            <Header/>

            <div className="middle_bg_pic"/>
            <div className="leader_board_wrapper">
                <h1>{items.title}</h1>
                <div className="updated_time">{items.updated && <b>Updated</b>}{items.updated}</div>

                <table className="winner_board_dynamic">
                    {/*<span className="board_center_line"/>*/}
                    <thead className="board_header_dynamic">
                    <tr>{Object.keys(items.data[0]).map((c, i) => i === 0 ? <th/> : <th>{c}</th>)}</tr>
                    </thead>
                    <tbody>
                    {code ? items?.data.map((x, key) => (
                        <tr key={key} className="board_content_dynamic">
                            {Object.values(x).map((y, i) => i === 0 ? <th>{y}</th> : <td>
                                <div>{y.replace(/"/g, "")}</div>
                            </td>)}

                        </tr>
                    )) : showModel()
                    }
                    </tbody>
                </table>
            </div>
            <Footer/>
        </>
    )
}
